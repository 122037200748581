import {
  ClickableOptions,
  DatasetData,
  LayerCircleData,
  LayerMultiOptionColorData,
  LayerPolygonData,
  SimpleOption,
  SmartPlanningPolygonData,
} from '@/utils/layers/layout-config'
import {AppRole, LayerRole} from '@/auth/roles'
import {
  INFILL_LSOA_STYLE,
  INFILL_LSOA_WHITE_STYLE,
  LOGARITHMIC_BLUE,
  OFFSTREET_PARKING,
  OFFSTREET_PARKING_PER_HOUSE,
  OFFSTREET_PARKING_RESIDENTIAL, POI_CLUSTERS_V1,
  PRETTY_BLUE_EV_COUNT,
  PRETTY_BLUE_STYLE,
  PRETTY_BLUE_WHITE_STYLE,
  TRAFFIC_CIRCLE,
  TRAFFIC_STYLE,
} from '@/utils/layers/layerStyles.utils'
import MapStyleUtils from '../mapStyle.utils'
import { Option } from '@/components/DSelect.vue'
import { MinMax } from '@/types/app'
import { formatByPercentage } from '@/libs/texts'
import DodonaBackend from '@/libs/loaders/dodona-backend/api-client'
import { RoleManager } from '@/libs/RoleManager'

export const trafficDensityLayer = 'Traffic density'
export const evForecast = 'EV forecast'

export type twoYearOption = 'baseline_2026_Q3' | 'baseline_scaled_2026_Q3'
export type fourYearOption = 'baseline_2028_Q3' | 'baseline_scaled_2028_Q3'
export type AllEvOptions = twoYearOption | fourYearOption

export const forecastPeriod = [
  { label: '2 years', value: 2 },
  { label: '4 years', value: 4 },
] as Option[]

export const forecastMetric = [
  // Gets number of vehicles
  { label: 'Predicted range of electrical vehicles', value: 'predicted_range_of_electrical_vehicles' },
  // Gets the ev in percantage
  { label: 'Predicted range of ev uptake', value: 'predicted_range_of_ev_uptake' },
] as Option[]

export type EvForecastMetric = 'predicted_range_of_electrical_vehicles' | 'predicted_range_of_ev_uptake'

export const layersUK = (roleManager: RoleManager): DatasetData[] => {
  const layers: (LayerPolygonData | LayerCircleData | LayerMultiOptionColorData)[] = [
    new LayerPolygonData({
      minZoom: 13,
      name: 'Car ownership density',
      group: 'Demographic data',
      description: 'Average number of cars owned per household',
      dataset: 'DEMOGRAPHY_CAR_OWNERSHIP',
      options: new SimpleOption('cars_per_household'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Cars per household',
          property: 'cars_per_household',
          rounded: 2,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'Residential property price',
      group: 'Demographic data',
      description: 'Median property price',
      dataset: 'DEMOGRAPHY_PROPERTY_PRICE',
      options: new SimpleOption('prop_price'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },

      tooltips: [
        {
          text: 'Median property price',
          property: 'prop_price',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'Population density',
      group: 'Demographic data',
      description: 'Population count per square kilometer',
      dataset: 'DEMOGRAPHY_POP_DENSITY',
      options: new SimpleOption('pop_density'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },

      tooltips: [
        {
          text: 'Population density per square km',
          property: 'pop_density',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'Household income',
      group: 'Demographic data',
      description: 'Median household income',
      dataset: 'DEMOGRAPHY_INCOME_DATA',
      options: new SimpleOption('median_income'),
      paintStyle: LOGARITHMIC_BLUE,
      paintStyleOptions: {
        'roadmap': LOGARITHMIC_BLUE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Median household income',
          property: 'median_income',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'EV registrations by LSOA (Q4 2022) - Private',
      group: 'Other data',
      genericApi: true,
      dataset: 'EV registrations by LSOA - Private',
      options: new SimpleOption('value'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered private EVs in Q4 of 2022',
          property: 'value',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'EV registrations by LSOA (Q4 2022) - Company',
      group: 'Other data',
      genericApi: true,
      dataset: 'EV registrations by LSOA - Company',
      options: new SimpleOption('value'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered company EVs in Q4 of 2022',
          property: 'value',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'EV registrations by LSOA (Q3 2023) - Private',
      group: 'Other data',
      genericApi: true,
      dataset: 'EV registrations by LSOA 2024',
      options: new SimpleOption('latest_private'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered private EVs in Q3 of 2023',
          property: 'latest_private',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'EV registrations by LSOA (Q3 2023) - Company',
      group: 'Other data',
      genericApi: true,
      dataset: 'EV registrations by LSOA 2024',
      options: new SimpleOption('latest_company'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered company EVs in Q3 of 2023',
          property: 'latest_company',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'EV registrations by LSOA (Q1 2024) - Private',
      group: 'Other data',
      genericApi: true,
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      options: new SimpleOption('ev_latest_private'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered private EVs in Q1 of 2024',
          property: 'ev_latest_private',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'EV registrations by LSOA (Q1 2024) - Company',
      group: 'Other data',
      genericApi: true,
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      options: new SimpleOption('ev_latest_company'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered company EVs in Q1 of 2024',
          property: 'ev_latest_company',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'All vehicles registrations by LSOA (Q3 2023) - Private',
      group: 'Other data',
      genericApi: true,
      dataset: 'Vehicle registrations by LSOA 2024',
      options: new SimpleOption('all_cars_latest_private'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered private vehicles in Q3 of 2023',
          property: 'all_cars_latest_private',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'All vehicles registrations by LSOA (Q3 2023) - Company',
      group: 'Other data',
      genericApi: true,
      dataset: 'Vehicle registrations by LSOA 2024',
      options: new SimpleOption('all_cars_latest_company'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered company vehicles in Q3 of 2023',
          property: 'all_cars_latest_company',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'All vehicles registrations by LSOA (Q1 2024) - Private',
      group: 'Other data',
      genericApi: true,
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      options: new SimpleOption('all_cars_latest_private'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered private vehicles in Q1 of 2024',
          property: 'all_cars_latest_private',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'All vehicles registrations by LSOA (Q1 2024) - Company',
      group: 'Other data',
      genericApi: true,
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      options: new SimpleOption('all_cars_latest_company'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Number of registered company vehicles in Q1 of 2024',
          property: 'all_cars_latest_company',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 10,
      role: LayerRole.evCountByLa,
      name: 'EV Count by LA (2021)',
      group: 'Other data',
      description: 'Total number of registered electric vehicles by local authority 2021',
      dataset: 'EV_COUNT',
      options: new SimpleOption('total'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },

      tooltips: [
        {
          text: 'Number of registered EVs',
          property: 'total',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      minZoom: 13,
      role: LayerRole.predictedOffstreetParkingResidential,
      name: 'Predicted offstreet parking in residential areas, UK',
      group: 'Other data',
      description: 'Offstreet parking in residential areas, UK',
      genericApi: true,
      dataset: 'Predicted offstreet parking in residential areas, UK',
      options: new SimpleOption('name'),
      paintStyle: OFFSTREET_PARKING_RESIDENTIAL,
      disableMinMax: true,
    }),

    new LayerPolygonData({
      minZoom: 13,
      name: 'Population age',
      group: 'Demographic data',
      description: 'Median population age',
      dataset: 'DEMOGRAPHY_POPULATION_MEDIAN_AGE',
      options: new SimpleOption('median_age'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Median population age',
          property: 'median_age',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      role: LayerRole.householdDensity,
      minZoom: 13,
      name: 'Household density',
      group: 'Demographic data',
      description: 'Number of households per square km',
      dataset: 'DEMOGRAPHY_HOUSEHOLD_DENSITY',
      options: new SimpleOption('household_density'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: 'Households per square km',
          property: 'household_density',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      role: LayerRole.pctFlat,
      minZoom: 13,
      name: 'Flats percent',
      group: 'Demographic data',
      description: 'Percentage of households living in flats',
      dataset: 'DEMOGRAPHY_PCT_FLAT',
      options: new SimpleOption('pct_flat'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: '% of households in flats',
          property: 'pct_flat',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      role: LayerRole.pctDetached,
      minZoom: 13,
      name: 'Detached houses percent',
      group: 'Demographic data',
      description: 'Percentage of households living in detached houses',
      dataset: 'DEMOGRAPHY_PCT_DETACHED',
      options: new SimpleOption('pct_detached'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: '% of households in detached houses',
          property: 'pct_detached',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      role: LayerRole.pctSemiDetached,
      minZoom: 13,
      name: 'Semi-detached houses percent',
      group: 'Demographic data',
      description: 'Percentage of households living in semi-detached houses',
      dataset: 'DEMOGRAPHY_PCT_SEMI_DETACHED',
      options: new SimpleOption('pct_semi_detached'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: '% of households in semi-detached houses',
          property: 'pct_semi_detached',
          rounded: 0,
        },
      ],
    }),

    new LayerPolygonData({
      role: LayerRole.pctTerraced,
      minZoom: 13,
      name: 'Terraced houses percent',
      group: 'Demographic data',
      description: 'Percentage of households living in terraced houses',
      dataset: 'DEMOGRAPHY_PCT_TERRACED',
      options: new SimpleOption('pct_terraced'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      tooltips: [
        {
          text: '% of households in terraced houses',
          property: 'pct_terraced',
          rounded: 0,
        },
      ],
    }),

    //traffic data
    new LayerCircleData({
      name: 'Traffic flows',
      group: 'Traffic data',
      minZoom: 11,
      dataset: 'TRAFFIC_COUNT',
      paintStyle: TRAFFIC_CIRCLE,
      featureProperty: 'vehicle_count',
    }),

    new LayerPolygonData({
      role: LayerRole.trafficDensity,
      name: trafficDensityLayer,
      group: 'Traffic data',
      description: 'Display daily average traffic density.',
      minZoom: 13,
      dataset: 'TRAFFIC_DENSITY',
      paintStyle: TRAFFIC_STYLE,
      mapTypeStyle: MapStyleUtils.greyscaleMapStyles,
      tooltips: [
        {
          property: 'w',
          text: 'Whole week daily average',
          rounded: 0,
        },
        {
          property: 'we',
          text: 'Weekend daily average',
          rounded: 0,
        },
        {
          property: 'wd',
          text: 'Weekday daily average',
          rounded: 0,
        },
      ],
      options: new ClickableOptions(
        'Show daily average traffic density for:',
        [
          {
            name: 'Whole week',
            featureProperty: 'w',
          },
          {
            name: 'Weekend',
            featureProperty: 'we',
          },
          {
            name: 'Week days',
            featureProperty: 'wd',
          },
        ],
      ),
    }),

    // layers other data
    new LayerPolygonData({
      role: LayerRole.predictedOffstreetParkingv1,
      minZoom: 13,
      name: 'Predicted offstreet parking - v1',
      group: 'Other data',
      description: 'Model predictions about off-street parking',
      dataset: 'OFFSTREET_PARKING',
      version: 'v1',
      options: new SimpleOption('offp_cat'),
      paintStyle: OFFSTREET_PARKING_PER_HOUSE,
      disableMinMax: true,
    }),

    new LayerPolygonData({
      role: LayerRole.predictedOffstreetParkingv211b,
      minZoom: 13,
      name: 'Predicted offstreet parking - v2.1.1 by LSOA',
      group: 'Other data',
      description: 'Model predictions about off-street parking',
      dataset: 'OFFSTREET_PARKING',
      version: 'v2.1.1b',
      options: new SimpleOption('offp_cat'),
      paintStyle: OFFSTREET_PARKING,
      disableMinMax: true,
    }),

    new LayerPolygonData({
      role: LayerRole.predictedOffstreetParkingv211,
      minZoom: 13,
      name: 'Predicted offstreet parking - v2.1.1',
      description: 'Model predictions about off-street parking',
      group: 'Other data',
      dataset: 'OFFSTREET_PARKING',
      version: 'v2.1.1',
      options: new SimpleOption('offp_cat'),
      paintStyle: OFFSTREET_PARKING_PER_HOUSE,
      disableMinMax: true,
    }),

    new LayerPolygonData({
      role: LayerRole.poiClusteringV1,
      minZoom: 11,
      name: 'Poi Clusters v1',
      group: 'Other data',
      description: 'Clusters of POI grouped by similar characteristics',
      dataset: 'POI_CLUSTERS',
      version: 'v1',
      options: new SimpleOption('cluster'),
      paintStyle: POI_CLUSTERS_V1,
      tooltips: [{ text: 'Cluster name:', property: 'cluster' }],
      disableMinMax: true,
    }),

    new LayerPolygonData({
      role: LayerRole.localAuthorityLayer,
      minZoom: 9,
      name: 'Local authority districts',
      description: 'Show local authority districts',
      group: 'Other data',
      dataset: 'LA_2021_BORDERS',
      options: new SimpleOption('name'),
      paintStyle: INFILL_LSOA_STYLE,
      tooltips: [{ text: 'District name:', property: 'name' }],
      disableMinMax: true,
    }),
  ]

  if (roleManager.hasRole(AppRole.evRegistrationForecast)) {
    const data = new EvPredictionLayer({
      name: evForecast,
      group: 'Demographic data',
      description: 'EV forecast prediction',
      dataset: 'EV_FORECAST',
      minZoom: 13,
      genericApi: false,
      paintStyle: PRETTY_BLUE_EV_COUNT('baseline_2026_Q3'),
      tooltips: [],
      options: new SimpleOption('baseline_2026_Q3'),
    })
    data.updateOptions(2, 'predicted_range_of_electrical_vehicles') 
  
    layers.push(data)
  }
  
  return layers
}


export function getGenericLayerConfiguration(name: string) {
  return new LayerPolygonData({
    name: name,
    group: 'Generic Data',
    dataset: name,
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    disableMinMax: false,
    genericApi: true,
    tooltips: [{ text: 'Name:', property: 'name' }, { text: 'Value:', property: 'value' }],
  })
}

export function getAreaInsightConfiguration() {
  return new LayerPolygonData({
    minZoom: 14,
    name: 'Area insight',
    group: 'Demographic data',
    description: 'Area insight description',
    dataset: 'AREA_INSIGHT',
    options: new SimpleOption('prop_price'),
    paintStyle: PRETTY_BLUE_STYLE,
    tooltips: [
      {
        text: 'EV count',
        property: 'ev_total',
        rounded: 0,
      },
      {
        text: 'Median household income',
        property: 'median_income',
        rounded: 0,
      },
      {
        text: 'Median property price',
        property: 'prop_price',
        rounded: 0,
      },
      {
        text: 'Number of Short Stay POIs',
        property: 'poi_short',
        rounded: 0,
      },
      {
        text: 'Number of Long Stay POIs',
        property: 'poi_long',
        rounded: 0,
      },
    ],
  })
}

export function getLocalInfillLsoaConfiguration() {
  return new SmartPlanningPolygonData({
    name: 'infill-lsoa',
    group: 'infill-lsoa',
    dataset: 'LSOA_2011_BORDERS',
    minZoom: 15,
    disableMinMax: true,
    options: new SimpleOption(''),
    paintStyle: INFILL_LSOA_STYLE,
    paintStyleOptions: {
      'roadmap': INFILL_LSOA_STYLE,
      'hybrid': INFILL_LSOA_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Population',
        property: 'population',
      },
    ],
  }) 
}


export class EvPredictionLayer extends LayerPolygonData {
  metricType = 'predicted_range_of_electrical_vehicles' as EvForecastMetric
  baseline = 'baseline_2026_Q3' as AllEvOptions
  updateOptions(year: 2 | 4, type: EvForecastMetric) {
    const selectedYear = year === 2 ? '2026' : '2028'
    // end version should be 'baseline_2026_Q3' | 'baseline_scaled_2026_Q3'
    const selectedType = type === 'predicted_range_of_electrical_vehicles' ? '' : 'scaled_' 
    this.metricType = type
    const baseline = `baseline_${selectedType}${selectedYear}_Q3` as AllEvOptions
    const optimisticOption = `optimistic_${selectedType}${selectedYear}_Q3` 
    const pessimisticOption = `pessimistic_${selectedType}${selectedYear}_Q3`  

    this.baseline=baseline
    const paintStyle = PRETTY_BLUE_EV_COUNT(baseline)
    this.rendererProperties = paintStyle.rendererProp
    this.mapStyle = paintStyle.mapStyle
    this.tooltips = [{
      text: 'Ev forecast prediction',
      property: optimisticOption,
      valueTransform: (value: any, tooltipView) => {
        if (type !== 'predicted_range_of_electrical_vehicles') {
          return `${formatByPercentage(tooltipView.getProperty(pessimisticOption) as number, 0)} - ${formatByPercentage(tooltipView.getProperty(optimisticOption) as number, 0)}`
        } else {
          return `${Math.floor(tooltipView.getProperty(pessimisticOption) as number / 10) * 10} - ${Math.floor(tooltipView.getProperty(optimisticOption) as number / 10) * 10}`
        }
      },
    }, 
    ],
    this.options = new SimpleOption(baseline)
  }

  async getMinMax(api: DodonaBackend, geometry: string, clipByRegionId?: number): Promise<MinMax> {
    if (this.genericApi) {
      return await api.getGenericApiMinMax(this.dataset, geometry, this.getFeatureProperty(), false)
    }

    return await api.minMax(this.dataset, geometry, clipByRegionId, false, this.baseline)
  }
}