import {AxiosInstance, AxiosRequestConfig} from 'axios'
import {
  AreaProperties, ChargerDetails,
  ChargerFeatureProperties,
  ChargerUtilization,
  CountryCode,
  CountryProperties,
  MinMax,
  POIFeatureProperties,
  PredictedUtilizationFeatureProperties,
  WardFeatureProperties,
} from '@/types/app'
import {Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon, Point, Polygon} from 'geojson'
import {
  ChargerRequestType,
  RegionConfiguration, UpdateLabelSetsRequest,
  UploadSimpleType,
  UploadSitesResponse,
  User,
  WorkspaceChargerSetups,
  WorkspaceChecklists,
} from '@/libs/loaders/dodona-backend/types'
import {Constraint, FeasibilityValue} from '@/libs/constraints/types'
import {
  SiteProperties,
  SiteScoreDetail,
  SiteSource,
  SiteStatus,
  SiteStatusCount,
  Workspace,
  WorkspaceIndexItem,
  WorkspaceNote,
  WorkspaceSite,
} from '@/types/workspace'
import {UtilizationStatisticsStore} from '@/stores/utilization-statistics.store'
import {OrganizationConfiguration, ToastIgnore} from '@/libs/auth/types'
import {CatchmentAreaFeatureProperties, CatchmentAreaType} from '@/types/catchment-area'
import {SiteModel} from '@/models/SiteSelection/Site.model'
import {
  ChargerPoint,
  ChargerStation,
  SiteCharger,
  StatusHistory,
  StatusUpdate,
  WorkspaceLogHistory,
  WorkspaceStatus,
  WorkspaceStatusBase,
} from '@/models/admin/Configuration.model'
import {ChargerUtils} from '@/utils/ChargerUtils'
import {ScoreExplanation, SiteImage, SiteSignalData, WardImage} from '@/types/site'
import {CustomSiteField, CustomSiteFieldValue, CustomSiteUidField, ReadableCustomSiteField} from '@/types/custom-fields'
import {PortfolioConfig} from '@/models/portfolio/Portfolio.model'
import {Dictionary, keyBy} from 'lodash'
import {deleteSite, editSite, EditSiteParams, getWorkspace, moveSite, OptionalSiteParams} from '@/libs/workspace'
import {
  Checklist,
  ChecklistModelMapper,
  ChecklistOutput,
  SiteChecklistOutput,
  SiteChecklistPerSite,
} from '@/models/admin/Checklist.model'
import {CategoryType, DatasetBatch, GenericDatasetGeoJsonProperties, GenericDatasetOutput} from '@/models/admin/GenericDataset.model'
import {readableSiteMetrics, SiteMetrics} from '@/libs/siteDetails'
import {toPresentableArea} from '@/libs/helpers'
import {AllNearbyEvse} from '@/models/charger/charger'
import {
  AnnotatedImageData,
  SiteCaptureUpdate,
  SiteCapturing, SitePublicCaptures,
  toSiteCapture,
} from '@/models/site/SiteCapture.model'
import {PartialSiteParameter, SiteParameter} from '@/types/site-parameters'
import {WorkspaceHistory} from '@/libs/workspaceList'
import {CoverageDatasetData} from '@/components/SiteSelection/Toolbox/Layers/Coverage.vue'
import { DataSource } from '@/stores/dataset-source.store'
import { CompanyTitle, LandAnalysisCompany, LandTitle } from '@/stores/panel-toolbox/land-analysis.store'
import { ScoreMissingReason } from '@/models/SiteSelection/SiteScore.model'
import { PropertyFilter } from '@/utils/layers/layout-config'
import {LabelSet} from '@/stores/label-sets.store'

export enum LogType {
  Workspace = 'workspace_history',
  Site = 'site_history'
}


interface PoiParams {
  geometry: string
  dataset?: string
  filter_properties?: PropertyFilter[]
  clip_by_region_id?: number
}

interface ChargersParams {
  geometry: string
  n_days?: number
  clip_by_region_id?: string | number
}

export interface FeasibilityCheckerParams {
  workspace_id?: string,
  assessment_name: string,
  feasibility_values: Partial<Constraint>[]
}

export interface PredictedUtilizationParams {
  geometry: string
  assessment_name: string
  max_score?: number
  max?: number
  min_score?: number
  min?: number
  workspace_id?: string
  zoom?: number
}

interface SkippedConstraints {
  constraint_model: {
    skipped_constraint_names: string[]
  }
}

interface ParkingSitePayload {
  workspace_id: string
  parking_operator_name: string
  assessment_name: string
}

export type PredictedUtilizationResponse
  = FeatureCollection<Point | Polygon, PredictedUtilizationFeatureProperties>
  & SkippedConstraints

interface CustomFeature<G extends Geometry | null = Geometry, P = GeoJsonProperties> extends Feature<G, P> {
  utilization: ChargerUtilization
}

interface CustomFeatureCollection<G extends Geometry | null = Geometry, P = GeoJsonProperties> extends FeatureCollection<G, P> {
  statistics: UtilizationStatisticsStore
  features: CustomFeature<G, P>[]
}

export interface DatasetPayload {
  dataset: string 
  version: string
  source_timestamp: string 
  batch_description: string 
  source_file_internal_location: string
  file: File | null, 
  name_property: string 
  value_property: string
  additional_properties: Record<string, string>
  file_type: 'XLSX' | 'CSV' | 'GEOJSON'
  publish_date: string,
  file_hash_code: string
  value: Record<string, string>
  // Required and is organization name by default in geometry endpoints
  source: string
}

export default class DodonaBackend {
  private reportingBaseUrl: string = ''
  private datasetBaseUrl: string = ''
  readonly c: AxiosInstance

  constructor(client: AxiosInstance) {
    this.c = client
  }

  public setDatasetUrl(url: string) {
    this.datasetBaseUrl = url
  }

  public setReportingUrl(url: string) {
    this.reportingBaseUrl = url
  }

  public get client(): AxiosInstance {
    if (!this.c) {
      throw new Error('HTTP client for API configured')
    }

    return this.c
  }


  async organizationConfig(): Promise<OrganizationConfiguration> {
    return this.client
      .get<OrganizationConfiguration>('/api/v2/organization/settings')
      .then(({ data }) => data)
  }

  async getGenericDataset(dataset: string, geometry: string, clipped = false, clipByRegionId?: number): Promise<FeatureCollection<Point, GenericDatasetGeoJsonProperties>> {
    return this.client.get('/api/v1/generic_dataset', {
      baseURL: this.datasetBaseUrl,
      params: {
        geometry: geometry,
        dataset: dataset,
        clipped: clipped,
        clip_by_region_id: clipByRegionId,
      },
    })
      .then(res => res.data)
  }

  async getCoverageGenericDatasets(): Promise<CoverageDatasetData[]> {
    return this.client.get('/api/v1/generic_dataset/dataset?include_all=true', {
      baseURL: this.datasetBaseUrl,
    })
      .then(res => res.data)
  }

  async getCoverageDatasets(): Promise<CoverageDatasetData[]> {
    return this.client.get('/api/v1/dataset/dataset', {
      baseURL: this.datasetBaseUrl,
    })
      .then(res => res.data)
  }

  async getCoverageForGenericDataset(dataset: string): Promise<FeatureCollection<Point, GenericDatasetGeoJsonProperties>> {
    return this.client.get(`/api/v1/generic_dataset/coverage?dataset=${dataset}`, {
      baseURL: this.datasetBaseUrl,
    })
      .then(res => res.data)
  }

  async getCoverageForDataset(dataset: string): Promise<FeatureCollection<Point, GenericDatasetGeoJsonProperties>> {
    return this.client.get(`/api/v1/dataset/coverage?dataset=${dataset}`, {
      baseURL: this.datasetBaseUrl,
    })
      .then(res => res.data)
  }

  async getAreaInsight(geometry: string, coordinates: string, clipped = false): Promise<FeatureCollection<Point, any>> {
    return this.client.get('/api/v1/area-insight', {
      baseURL: this.datasetBaseUrl,
      params: {
        coordinates, 
        geometry,
        dataset: 'AREA_INSIGHT',
        clipped: clipped,
      },
    })
      .then(res => res.data)
  }

  async poi(params: PoiParams): Promise<FeatureCollection<Point, POIFeatureProperties>> {
    interface response {
      poi_category: string
      category?: string
      poi_dwell_time?: string
      name?: string
    }
    const endpoint = '/api/v1/dataset'
    params.dataset = 'POI'
    const cTimeLabel = `api${endpoint}`
    console.time(cTimeLabel)

    return this.client
      .post<FeatureCollection<Point, response>>(
        endpoint,
        params, { baseURL: this.datasetBaseUrl},
      )
      .then(({ data }) => {
        this.checkDataValidity(data)

        console.timeLog(cTimeLabel, { count: data.features.length })
        return {
          type: data.type,
          features: data.features.map((feature) => ({
            ...feature,
            properties: {
              category: feature.properties.poi_category,
              parentCategory: feature.properties.category,
              dwellTime: feature.properties.poi_dwell_time,
              name: feature.properties.name,
            },
          })),
        } as FeatureCollection<Point, POIFeatureProperties>
      })
      .finally(() => console.timeEnd(cTimeLabel))
  }

  async chargers(params: ChargersParams, requestType: ChargerRequestType): Promise<CustomFeatureCollection<Point, ChargerFeatureProperties>> {
    return this.client
      .post<CustomFeatureCollection<Point, ChargerFeatureProperties>>(
        'api/v1/dataset',
        {
          ...params,
          dataset: requestType,
        },
        {
          baseURL: this.datasetBaseUrl,
        },
      )
      .then(({ data }) => {
        this.checkDataValidity(data)
        return data
      })
  }

  catchmentArea(type: CatchmentAreaType, geometry: FeatureCollection<Point, CatchmentAreaFeatureProperties>): Promise<FeatureCollection<Polygon, CatchmentAreaFeatureProperties>> {
    const endpoint = '/api/v1/catchment-area'
    const cTimeLabel = `api${endpoint}`


    geometry.features = geometry.features.map(d => {
      return {
        ...d,
        properties: {
          ...d.properties,
          ca_type: type,
        },
      }
    })

    console.time(cTimeLabel)
    return this.client
      .post<FeatureCollection<Polygon, CatchmentAreaFeatureProperties>>(
        endpoint,
        geometry,
        {
          baseURL: this.datasetBaseUrl,
        },
      )
      .then(({ data }) => data)
      .finally(() => console.timeEnd(cTimeLabel))
  }


  async getWards(geometry: string): Promise<FeatureCollection<Polygon, WardFeatureProperties>> {
    const endpoint = '/api/v1/wards'
    const cTimeLabel = `api${endpoint}`

    console.time(cTimeLabel)
    return this.client
      .post<FeatureCollection<Polygon, WardFeatureProperties>>(
        endpoint,
        geometry,
        {
          baseURL: this.datasetBaseUrl,
        },
      )
      .then(({ data }) => data)
      .finally(() => console.timeEnd(cTimeLabel))
  }

  /**
   * Generic geo-json fetching endpoint
   */
  async geo<G extends Geometry | null = Geometry, P = Record<string, unknown>>(
    reqConfig: AxiosRequestConfig,
    errorOnEmpty = false,
  ): Promise<FeatureCollection<G, P>> {
    const cTimeLabel = `api${reqConfig.url}`
    console.time(cTimeLabel)
    reqConfig.baseURL = this.datasetBaseUrl
    return this.client
      .request(reqConfig)
      .then(({ data }) => {
        if (errorOnEmpty && (!data.features || data.features.length === 0)) {
          throw new Error('No geometry returned from API.')
        }

        console.timeLog(cTimeLabel, { count: data.features.length })
        return data
      })
      .finally(() => console.timeEnd(cTimeLabel))
  }

  /**
   * Min/max for bbox fetching endpoint
   */
  async minMax(
    dataset: string,
    geometry: string,
    clipByRegionId?: number,
    errorOnEmpty = false,
    prop?: string,
  ): Promise<MinMax> {
    return this.client
      .get<MinMax>('/api/v1/dataset/min-max', {
        baseURL: this.datasetBaseUrl,
        params: {
          dataset: dataset,
          geometry: geometry,
          clip_by_region_id: clipByRegionId,
          prop,
        },
      })
      .then(({ data }) => {
        if (errorOnEmpty && (!data.min || !data.max)) {
          throw new Error('No min and max returned from API.')
        }
        return data
      })
  }

  async getGenericApiMinMax(
    dataset: string,
    geometry: string,
    featureProperty: string,
    errorOnEmpty = false,
  ): Promise<MinMax> {
    return this.client
      .get<MinMax>(`/api/v1/generic_dataset/min-max/${featureProperty}`, {
        baseURL: this.datasetBaseUrl,
        params: {
          geometry: geometry,
          dataset: dataset,
          clipped: false,
        },
      })
      .then(({ data }) => {
        if (errorOnEmpty && (!data.min || !data.max)) {
          throw new Error('No min and max returned from API.')
        }
        return data
      })
  }


  getWorkspace(workspaceId: string): Promise<Workspace> {
    return getWorkspace(this, workspaceId)
  }

  workspaceSites(id: string): Promise<WorkspaceSite[]> {
    return this.client
      .get<WorkspaceSite[]>('/api/v2/site/sites/' + id)
      .then((res) => res.data || [])
  }

  getAllworkspaceSites(site_statuses: string[] = []): Promise<{
    sites: WorkspaceSite[],
    statusCount: Dictionary<SiteStatusCount>
  }> {
    const params = new URLSearchParams()
    site_statuses.forEach(d => params.append('site_statuses', d.trim()))

    const query = site_statuses.length > 0 ? `?${params.toString()}` : ''

    return this.client
      .get<{
        sites: WorkspaceSite[],
        site_status_count: SiteStatusCount[]
      }>(`/api/v2/site${query}`)
      .then((res) => {
        return {
          sites: res.data.sites || [],
          statusCount: keyBy(res.data.site_status_count, 'status'),
        }
      })
  }

  siteSources(): Promise<SiteSource[]> {
    return this.client.get('/api/v2/site_source').then((res) => res.data)
  }

  siteStatuses(): Promise<SiteStatus[]> {
    return this.client.get('/api/v2/site_status').then((res) => res.data)
  }

  async getSearchHistory(): Promise<google.maps.places.AutocompletePrediction[]> {
    interface SearchHistoryItem {
      id: number
      search_data: google.maps.places.AutocompletePrediction
    }

    return this.client
      .get<SearchHistoryItem[]>('/api/v2/places')
      .then((res) => (res.data || []).map((item) => item.search_data))
  }

  async addToSearchHistory(item: google.maps.places.AutocompletePrediction): Promise<void> {
    return this.client.post('/api/v2/places', {
      place_id: item.place_id,
      structured_formatting: { ...item.structured_formatting },
    })
  }

  async downloadFileUploadSample(): Promise<Blob> {
    return this.client
      .get(
        '/api/v2/site_upload/sample',
        {
          headers: {
            'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        },
      ).then((res) => {
        return new Blob([res.data])
      })
  }

  async uploadSites(
    workspaceId: string,
    file: File,
    assessmentName: string | undefined,
  ): Promise<UploadSitesResponse> {
    const formData = new FormData()
    formData.append('file', file)

    const queryParams = new URLSearchParams()
    if (assessmentName !== undefined) {
      queryParams.append('assessment_name', assessmentName)
    }

    const url = `/api/v2/site_upload/${workspaceId}?${queryParams.toString()}`

    return this.client.post(url, formData).then((res) => res.data)
  }

  async uploadSimpleSites(
    workspaceId: string,
    assessment_name: string | undefined,
    sites: UploadSimpleType[],
  ): Promise<UploadSitesResponse> {
    return this.client
      .post(`/api/v2/site_upload/simple/${workspaceId}`, {
        assessment_name: assessment_name,
        sites,
      })
      .then((res) => res.data)
  }


  async uploadSitesLandAnalysis(
    workspaceId: string,
    assessment_name: string | undefined,
    company_name: string,
    title_numbers: string[],
  ): Promise<UploadSitesResponse> {
    return this.client
      .post(`/api/v2/site_upload/land-upload/${workspaceId}`, {
        assessment_name: assessment_name,
        company_name: company_name,
        title_numbers: title_numbers,
      })
      .then((res) => res.data)
  }

  /**
   * Throws an error if the data is not a valid FeatureCollection.
   */
  private checkDataValidity(data: FeatureCollection): void {
    if (typeof data !== 'object') {
      throw new Error('unexpected data format, expecting root object')
    }

    if (!data.type) {
      throw new Error('unexpected data format, root object property "type" missing')
    }

    if (!data.features || !Array.isArray(data.features)) {
      throw new Error('unexpected data format, root object property "features" missing or not an array')
    }
  }

  saveWorkspaceNote(note: string, workspaceId: string): Promise<void> {
    return this.client.post(`/api/v2/workspace/${workspaceId}/note`, {
      note: note,
    })
  }

  getLatestWorkspaceNote(workspaceId: string): Promise<WorkspaceNote | null> {
    return this.client
      .get(`/api/v2/workspace/${workspaceId}/note/latest`)
      .then((res) => res.data)
  }

  async getSitePngs(sites: string[]): Promise<SiteImage[]> {
    const siteParams = new URLSearchParams()
    sites.forEach((val) => siteParams.append('site_ids', val))

    return this.client
      .get('/api/v2/visualization/sites', {
        baseURL: this.reportingBaseUrl,
        params: siteParams,
      })
      .then((res) => res.data)
  }

  async getWardPngs(sites: string[]): Promise<WardImage[]> {
    const siteParams = new URLSearchParams()
    sites.forEach((val) => siteParams.append('site_ids', val))

    return this.client
      .get('/api/v2/visualization/wards', {
        baseURL: this.reportingBaseUrl,
        params: siteParams,
      })
      .then((res) => {
        return res.data
      })
  }

  async getSitesImage(sites: string[]): Promise<Blob> {
    const siteParams = new URLSearchParams()
    sites.forEach((val) => siteParams.append('site_ids', val))

    return this.client
      .get('/api/v2/visualization/sites_image', {
        baseURL: this.reportingBaseUrl,
        params: siteParams,
        headers: {
          'content-type': 'image/jpeg',
          accept: 'image/jpeg',
        },
        responseType: 'blob',
      })
      .then((res) => new Blob([res.data], { type: 'image/jpeg' }))
  }

  async convertDocxToPdf(document: File): Promise<Blob> {
    const formData = new FormData()
    formData.append('file', document)

    return this.client
      .post('/api/v2/reporting/convert_to_pdf', formData, {
        // baseURL: this.reportingBaseUrl,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      })
      .then((res) => new Blob([res.data], { type: 'application/pdf' }))
  }

  storeDataset(name: string, description: string, category: CategoryType, color: string | null, label: string | null): Promise<void> {
    return this.client.post(
      '/api/v1/generic_dataset/dataset',
      {
        name: name,
        description: description,
        category: category,
        color: color,
        label: label,
        list_on_fe: true,
      }, {baseURL: this.datasetBaseUrl},
    )
  }

  async uploadDatasetData(
    dataset: string,
    source: string,
    version: string,
    batch_description: string,
    internal_location: string,
    file: File,
    name_property: string,
    value_property: string,
  ): Promise<UploadSitesResponse> {
    const formData = new FormData()
    formData.append('dataset', dataset)
    formData.append('source', source)
    formData.append('version', version)
    formData.append('batch_description', batch_description)
    formData.append('source_file_internal_location', internal_location)
    formData.append('file', file)
    formData.append('name_property', name_property)
    formData.append('value_property', value_property)
    formData.append('file_type', 'XLSX')
    const headers = { 'Content-Type': 'multipart/form-data' }
    return this.client
      .post('/api/v1/generic_dataset/upload_file', formData, { headers, baseURL: this.datasetBaseUrl })
      .then((res) => res.data)
  }

  deleteDataset(datasetName: string): Promise<void> {
    return this.client.delete(`/api/v1/generic_dataset/dataset?name=${datasetName}&delete_dependencies=true` ,{baseURL: this.datasetBaseUrl})
  }

  getGenericDatasets(): Promise<GenericDatasetOutput[]> {
    return this.client.get('/api/v1/generic_dataset/dataset', { baseURL: this.datasetBaseUrl }).then((res) => res.data)
  }

  getGenericDatasetJobsRunningJobs(): Promise<DatasetBatch[]> {
    return this.client.get('/api/v1/generic_dataset/running_jobs', {baseURL: this.datasetBaseUrl}).then((res) => res.data)
  }

  getAvailableDatasetBatches(datasetName: string): Promise<DatasetBatch[]> {
    return this.client.get(`/api/v1/generic_dataset/get_batch_versions?dataset=${datasetName}`, {baseURL: this.datasetBaseUrl}).then((res) => res.data)
  }

  async unlockWorkspace(workspaceId: string): Promise<void> {
    await this.client.post(`/api/v2/workspace/${workspaceId}/unlock`)
  }

  async lockWorkspace(workspaceId: string): Promise<void> {
    await this.client.post(`/api/v2/workspace/${workspaceId}/lock`)
  }

  async getCountryData(countryCode: string): Promise<CountryProperties> {
    return this.client.get<FeatureCollection<Polygon | MultiPolygon, CountryProperties>>(`/api/v1/region/parent/${countryCode.toLowerCase()}`, {
      baseURL: this.datasetBaseUrl,
    }).then((response) => response.data.features[0].properties)
  }

  getChildRegions(countryCode: CountryCode, parentId: number | string): Promise<FeatureCollection<Polygon | MultiPolygon, AreaProperties>> {
    return this.client.get<FeatureCollection<Polygon | MultiPolygon, AreaProperties>>(`/api/v1/region/child/${countryCode.toLowerCase()}/${parentId}`, {
      baseURL: this.datasetBaseUrl,
    }).then((response) => ({
      type: 'FeatureCollection',
      features: response.data.features.map(toPresentableArea),
    }))
  }

  getNearbyEvseCount(siteId: string, distance: number): Promise<AllNearbyEvse> {
    return this.client
      .get(`/api/v2/site/nearby-evse-counts/${siteId}`, {params: {distance}})
      .then((res) => res.data)
  }

  async dataset(dataset: string, geometry: string, version?: string, clipByRegionId?: number, filterProperties: PropertyFilter[] = []): Promise<FeatureCollection> {
    return this.client.post(
      '/api/v1/dataset', {
        geometry: geometry,
        dataset: dataset,
        version: version,
        clip_by_region_id: clipByRegionId,
        filter_properties: filterProperties,
      },
      {
        baseURL: this.datasetBaseUrl,
      },
    )
      .then(data => data.data)
  }
  

  getChargerDetails(id?: number): Promise<ChargerDetails> {
    return this.client.get('/api/v1/charger-details', {
      baseURL: this.datasetBaseUrl,
      params: {
        region_id: id,
      },
    })
      .then(res => res.data)
  }


  getUsaDnos(geometry: string, dataset: string): Promise<string[]> {
    return this.client.get('/api/v1/generic_dataset/get_sources_by_dataset_and_geometry', {
      baseURL: this.datasetBaseUrl,
      params: {
        geometry,
        dataset,
        clipped: true,
      },
    }).then(res => res.data.sources)
  }

  getDNOs(geometry: string): Promise<string[]> {
    return this.client.get('/api/v1/dataset/dno-names', {
      baseURL: this.datasetBaseUrl,
      params: {
        geometry,
        dataset: 'DTSLSS',
      },
    }).then(res => res.data)
  }

  // used for fething new statuses
  getStatuses(): Promise<SiteStatus[]> {
    return this.client
      .get('/api/v2/site_status')
      .then((res) => {
        return res.data
      })
  }

  getStatusHistory(logType: LogType): Promise<StatusHistory[]> {
    return this.client
      .get('/api/v2/logs/status-history')
      .then(res => {
        return res.data[logType].map(WorkspaceLogHistory.toLog)
          .toSorted((a: StatusHistory, b: StatusHistory) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
          })
      })
  }

  createStatus(statusNames: string[]): Promise<string> {
    return this.client
      .post('/api/v2/site_status', statusNames)
      .then(({ data }) => data)
  }

  updateStatus(statuses: StatusUpdate[]): Promise<void> {
    return this.client
      .put('/api/v2/site_status', statuses)
  }

  archiveStatus(statusId: string): Promise<void> {
    return this.client
      .put(`/api/v2/site_status/${statusId}/archive`)
      .then((res) => res.data)
  }

  unarchiveStatus(statusId: string): Promise<void> {
    return this.client
      .put(`/api/v2/site_status/${statusId}/unarchive`)
      .then((res) => res.data)
  }

  getPredictedUtilization(params: PredictedUtilizationParams): Promise<PredictedUtilizationResponse> {
    const endpoint = params.workspace_id
      ? '/api/v2/site/workspace-prediction'
      : '/charge-point/prediction'

    return this.client.post(endpoint, params).then(res => res.data)
  }

  async getScoreExaplanation(siteId: string): Promise<ScoreExplanation[]> {
    return this.client.get(`/api/v2/site/${siteId}/score-explanation`)
      .then(res => {
        return res.data.map((res: ScoreExplanation) => {
          return {
            name: SiteModel.ModelScoreLabelMapper.get(res.name) || 'N/A',
            value: SiteModel.ModelScoreValueMapper.get(res.value) || 'N/A',
          }
        })
      })
  }

  listChargers(): Promise<ChargerStation[]> {
    return this.client
      .get('/api/v2/charger')
      .then((res) => {
        return res.data.map(ChargerUtils.serialize)
      })
  }

  connectorTypeList(): Promise<string[]> {
    return this.client.get('/api/v2/charger/connector-types')
      .then((res) => {
        return res.data
      })
  }

  addCharger(chargerStation: any): Promise<ChargerStation> {
    return this.client
      .post('/api/v2/charger', chargerStation)
      .then((res) => ChargerUtils.serialize(res.data))
  }

  createCharger(
    title: string,
    chargerPoint: ChargerPoint[],
  ): Promise<ChargerStation> {
    return this.client
      .post('/api/v2/charger', {
        title,
        charge_points: chargerPoint.map(data => {
          return {
            ...data,
            connectors: data.connectors.map(connector => {
              return {
                type: connector.type,
                output: connector.output || '',
              }
            }),
          }
        }),
      })
      .then((res) => ChargerUtils.serialize(res.data))
  }

  updateChargerTitle(chargerId: string, title: string): Promise<void> {
    return this.client
      .put(`/api/v2/charger/${chargerId}?title=${title}`)
      .then(res => res.data)
  }

  addChargerPoint(chargerId: string, payload: ChargerPoint[]): Promise<void> {
    return this.client
      .post(`/api/v2/charger/${chargerId}/charge_point`, payload)
  }

  deleteChargerPoint(chargerId: string, chargerPointId: string): Promise<void> {
    return this.client
      .delete(`/api/v2/charger/${chargerId}/charge_point/${chargerPointId}`)
  }

  chargerInUse(chargerId: string): Promise<boolean> {
    return this.client.get(`/api/v2/charger/${chargerId}/check-usage`)
      .then(res => res.data.usage_count !== 0)
  }

  archiveCharger(id: string): Promise<void> {
    return this.client
      .put(`/api/v2/charger/archive/${id}`)
      .then(() => {
        return
      })
  }

  unarchiveCharger(id: string): Promise<void> {
    return this.client
      .put(`/api/v2/charger/unarchive/${id}`)
      .then(() => {
        return
      })
  }

  async updateChargers(oldCharger: ChargerStation, title: string, payload: ChargerPoint[]): Promise<ChargerStation> {
    // delete previes charger ponts
    await Promise.all(oldCharger.chargerPoint.map((chargerPoint) => {
      if (chargerPoint.id) {
        return this.deleteChargerPoint(oldCharger.id, chargerPoint.id)
      }
      return Promise.resolve()
    }))

    // Add title if changed
    if (oldCharger.title !== title) {
      await this.updateChargerTitle(oldCharger.id, title)
    }


    await this.addChargerPoint(oldCharger.id, payload)

    return {
      ...oldCharger,
      title,
      chargerPoint: payload,
    }
  }

  deleteCharger(chargerId: string): Promise<any> {
    return this.client
      .delete(`/api/v2/charger/${chargerId}`)
  }

  listActiveChargerOnSite(siteId: string): Promise<SiteCharger[]> {
    return this.client
      .get(`/api/v2/charger/${siteId}/chargers`).then(res => res.data)
  }

  getChargerFromSite(siteId: string): Promise<SiteCharger[]> {
    return this.client
      .get(`/api/v2/charger/${siteId}/chargers`)
      .then((res) => {
        return res.data
      })
  }

  addChargerToSite(siteId: string, chargerId: string, quantity: number): Promise<SiteProperties> {
    return this.client
      .post(`/api/v2/charger/${siteId}/add?charger_id=${chargerId}&quantity=${quantity}`).then(res => res.data)
  }

  removeChargerToSite(siteId: string, chargerId: string): Promise<SiteProperties> {
    return this.client
      .delete(`/api/v2/charger/${siteId}/remove/${chargerId}`)
      .then(res => res.data)
  }

  editChargerToSite(siteId: string, oldChargerId: string, newChargerId: string, quantity: number): Promise<SiteProperties> {
    return this.client
      .post('/api/v2/charger/move', {
        'new_charger_id': newChargerId,
        'old_charger_id': oldChargerId,
        'site_id': siteId,
        'new_quantity': quantity,
      }).then(res => res.data)
  }

  getAllSiteCapturing(siteId: string): Promise<SiteCapturing[]> {
    return this.client
      .get(`/api/v2/site-capturing/site/${siteId}`, {}).then(res => {
        return res.data.map(toSiteCapture).toSorted((a: SiteCapturing, b: SiteCapturing) => a.position - b.position)
      })
  }

  deleteSiteCapture(siteCaptureId: number): Promise<void> {
    return this.client
      .delete(`/api/v2/site-capturing/${siteCaptureId}`, {}).then(res => {
        return res.data
      }) 
  }

  updateSiteCapture(siteCaptureId: number, params: SiteCaptureUpdate): Promise<void> {
    return this.client
      .put(`/api/v2/site-capturing/${siteCaptureId}`, params).then(res => {
        return res.data
      })
  }

  updateSiteCapturePosition(siteCaptureId: number, newPosition: number): Promise<void> {
    return this.client
      .put(`/api/v2/site-capturing/${siteCaptureId}/position/${newPosition}`).then(res => {
        return res.data
      })
  }

  updateAnnotatedCapturing(data: AnnotatedImageData): Promise<void> {
    const formData = new FormData()
    formData.append('image_with_polygons', data.image_with_polygons)
    formData.append('rectangles', JSON.stringify(data.rectangles))
    formData.append('polylines', JSON.stringify(data.polylines))
    formData.append('text_items', JSON.stringify(data.text_items))
    formData.append('image_items', JSON.stringify(data.image_items))
    formData.append('text_item_background', JSON.stringify(data.text_item_background))
    formData.append('image_items_text', JSON.stringify(data.image_items_text))
    formData.append('image_items_text_background', JSON.stringify(data.image_items_text_background))
    formData.append('name', data.name)


    return this.client
      .put(`/api/v2/site-capturing/annotate/${data.siteCaptureId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  }

  createSiteCapturing(siteId: string, obj: Omit<SiteCapturing, 'id' | 'was_moved'>): Promise<number> {
    return this.client
      .post('/api/v2/site-capturing', {
        ...obj,
        coordinates: obj.coordinates,
        site_id: siteId,
      }).then((res) => {
        return res.data as number
      })
  }

  createInitAnnotationImage(captureId: number): Promise<Blob> {
    return this.client
      .post(`/api/v2/site-capturing/annotate?site_capture_id=${captureId}`, {}, {
        responseType: 'blob',
      })
      .then((res) => {
        return new Blob([res.data], { type: 'image/png' })
      })
  }

  checkSiteSignal(siteID: string): Promise<SiteSignalData[]> {
    return this.client
      .post(`/api/v2/site_signal/${siteID}/check-signal-data`)
      .then((res) => {
        return res.data
      })
  }

  getSiteSignal(siteID: string): Promise<SiteSignalData[]> {
    return this.client
      .get(`/api/v2/site_signal/${siteID}/signal-data`)
      .then((res) => {
        return res.data
      })
  }

  async getCustomFields(): Promise<CustomSiteField[]> {
    return await this.client.get('/api/v2/custom_field/site_fields')
      .then((res) => {
        return res.data
      })
  }

  async getSiteCustomFieldValues(siteId: string): Promise<CustomSiteFieldValue[]> {
    return await this.client.get(`/api/v2/custom_field/site_field_values/${siteId}`)
      .then((res) => {
        return res.data
      })
  }

  async getReadableCustomFields(siteIds: string[], forExcel = false, forWord = false): Promise<ReadableCustomSiteField> {
    return this.client.post('/api/v2/custom_field/readable-site-field-values', {
      'site_ids': siteIds,
      'for_excel': forExcel,
      'for_word': forWord,
      'all': !forExcel && !forWord,
    }).then((res) => {
      const transformedData: ReadableCustomSiteField = {
        customFieldsPerSite: res.data.custom_fields_per_site,
      }
      return transformedData
    })
  }

  async saveCustomFieldValues(siteId: string, fieldId: number, value: string): Promise<void> {
    return this.client
      .post('/api/v2/custom_field/site_field_values', {
        'site_id': siteId,
        'custom_field_id': fieldId,
        'value': value,
      })
  }

  async saveCustomSiteField(field: CustomSiteUidField): Promise<void> {
    return this.client
      .post('/api/v2/custom_field/site_fields', {
        'label': field.label,
        'type': field.type,
        'options': field.options,
        'show_in_excel_workspace_report': field.show_in_excel_workspace_report,
        'show_in_docx_workspace_report': field.show_in_docx_workspace_report,
      })
  }

  async updateCustomSiteField(field: CustomSiteUidField): Promise<void> {
    return this.client
      .put('/api/v2/custom_field/site_fields', {
        'label': field.label,
        'type': field.type,
        'options': field.options,
        'custom_site_field_id': field.custom_site_field_id,
        'show_in_excel_workspace_report': field.show_in_excel_workspace_report,
        'show_in_docx_workspace_report': field.show_in_docx_workspace_report,
      })
  }

  deleteCustomSiteField(id: number): Promise<void> {
    return this.client.delete(`/api/v2/custom_field/site_fields/${id}`)
  }

  deleteCustomSiteFieldValues(id: number): Promise<void> {
    return this.client.delete(`/api/v2/custom_field/site_fields/${id}/values`)
  }

  listWorkspaceStatus(): Promise<WorkspaceStatus[]> {
    return this.client
      .get('/api/v2/workspace_status')
      .then((res) => {
        return res.data
      })
  }

  /**
   * Returns the ID of the new status
   * @param status_name
   */
  createWorkspaceStatus(status_names: string[]): Promise<string> {
    return this.client
      .post('/api/v2/workspace_status', status_names)
      .then(res => res.data)
  }

  deleteSite(siteId: string, reason = 'Deleted manually'): Promise<void> {
    return deleteSite(this, siteId, reason)
  }

  updateWorkspaceStatus(statuses: WorkspaceStatusBase[]): Promise<void> {
    return this.client
      .put('/api/v2/workspace_status', statuses)
  }

  archiveWorkspaceStatus(workspaceStatusId: string): Promise<void> {
    return this.client
      .put(`/api/v2/workspace_status/${workspaceStatusId}/archive`)
      .then(() => {})
  }

  unarchiveWorkspaceStatus(workspaceStatusId: string): Promise<void> {
    return this.client
      .put(`/api/v2/workspace_status/${workspaceStatusId}/unarchive`)
      .then(() => {})
  }

  restoreWorkspaces(): Promise<void> {
    return this.client
      .post('/api/v2/default')
      .then(() => {})
  }

  getStreetViewCanvas(siteId: string): Promise<Blob | null> {
    return this.client
      .get(
        `/api/v2/site_street_view_canvas/${siteId}`,
        {
          headers: {
            'content-type': 'image/png',
            accept: 'image/png',
          },
          responseType: 'blob',
        },
      )
      .then((res) => {
        if (res.status === 204) {
          return null
        }

        return res.data
      })
  }

  archiveStreetViewCanvas(siteId: string): Promise<void> {
    return this.client
      .post(`/api/v2/site_street_view_canvas/${siteId}/archive`)
      .then(() => {})
  }

  saveStreetViewCanvas(siteId: string, file: File): Promise<void> {
    const formData = new FormData()
    formData.append('site_id', siteId)
    formData.append('file', file)
    const headers = { 'Content-Type': 'multipart/form-data' }
    return this.client
      .post(`/api/v2/site_street_view_canvas/${siteId}`, formData, { headers })
      .then(() => {})
  }

  getPortfolioConfig(): Promise<null | PortfolioConfig> {
    return this.client
      .get('/api/v2/portfolio/config')
      .then((res) => res.data)
  }

  setPortfolioConfig(workspaceIds: string[], siteStatusesIds: string[]): Promise<PortfolioConfig> {
    return this.client
      .post('/api/v2/portfolio/config', {
        config: {
          workspaces: workspaceIds,
          site_statuses: siteStatusesIds,
        },
      })
      .then(res => res.data)
  }

  getOrganizationUsers(): Promise<User[]> {
    return this.client.get('/api/v2/organization/users').then(res => res.data)
  }

  // When we are updating the marker we are deleting the previous one and create
  // a new one where we copy the configuration from the previous to the new one
  async moveSite(site_id: string, lanLng: any): Promise<WorkspaceSite> {
    return await moveSite(
      this,
      lanLng,
      site_id,
    )
  }

  async listChecklist(): Promise<ChecklistOutput[]> {
    return this.client
      .get('/api/v2/checklist')
      .then((res) => res.data || [])
  }

  async addChecklist(label: string, position: number): Promise<ChecklistOutput> {
    return this.client
      .post('/api/v2/checklist', {
        label,
        position,
      })
      .then((res) => res.data)
  }

  async updateChecklist(checklist: Checklist[]): Promise<ChecklistOutput> {
    const payload = checklist.map(ChecklistModelMapper.toBackendChecklist)
    return this.client
      .put('/api/v2/checklist/update', payload)
      .then((res) => res.data)
  }

  async deleteChecklist(checklistId: string): Promise<void> {
    return this.client
      .delete(`/api/v2/checklist/${checklistId}`)
  }

  async listSiteCheckList(siteId: string): Promise<SiteChecklistOutput[]> {
    return this.client
      .get(`/api/v2/checklist/site/${siteId}`)
      .then((res) => res.data || [])
  }

  async SiteCheckListPerSite(siteIds: string[]): Promise<SiteChecklistPerSite> {
    return this.client
      .post('/api/v2/checklist/checklist-per-site', {
        site_ids: siteIds,
      }).then((res) => {
        const transformedData: SiteChecklistPerSite = {
          checklistsPerSite: res.data.checklists_per_site,
        } || {checklistsPerSite: {}}
        return transformedData
      })
  }

  async addChecklistOnSite(siteId: string, checklistId: string): Promise<SiteChecklistOutput[]> {
    return this.client
      .post(`/api/v2/checklist/add/${siteId}/${checklistId}`)
      .then((res) => res.data || [])
  }

  async removeChecklistOnSite(siteId: string, checklistId: string): Promise<SiteChecklistOutput[]> {
    return this.client
      .delete(`/api/v2/checklist/remove/${siteId}/${checklistId}`)
      .then((res) => res.data || [])
  }

  async gettingAllParkingOperators(): Promise<string[]> {
    const endpoint = '/api/v1/dataset/key-values?property_key=parking_operator_class&dataset=PARKOPEDIA_PARKINGS'
    return this.client.get(endpoint, {
      baseURL: this.datasetBaseUrl,
    })
      .then(({ data }) => {
        return data.map((d: any) => d.key_value)
      })
  }

  async gettingAllParkingCategories(): Promise<string[]> {
    const endpoint = '/api/v1/dataset/key-values?property_key=parking_category_class&dataset=PARKOPEDIA_PARKINGS'
    return this.client.get(endpoint, {
      baseURL: this.datasetBaseUrl,
    })
      .then(({ data }) => {
        return data.map((d: any) => d.key_value)
      })
  }

  async getSiteMetrics(siteList: string[]): Promise<SiteMetrics> {
    const endpoint = '/api/v2/site-metrics'

    return this.client.post(endpoint, siteList, {
      baseURL: this.reportingBaseUrl,
    })
      .then(({data}) => {
        return readableSiteMetrics(data as SiteMetrics)
      })
  }

  async getSiteScore(siteId: string): Promise<SiteScoreDetail[]> {
    return this.client.get(`/api/v2/site_score/${siteId}`)
      .then((res) => {
        return res.data
      })
  }

  async createSite(
    api: DodonaBackend,
    workspaceId: string,
    coordinates: google.maps.LatLngLiteral,
    assessmentName: string | undefined,
    options?: OptionalSiteParams,
  ): Promise<WorkspaceSite> {
    return api.client
      .post('/api/v2/site', {
        coordinates,
        workspace_id: workspaceId,
        assessment_name: assessmentName || undefined,
        ...options,
      })
      .then((res) => res.data)
  }

  async updateSiteAssessmentScore(
    site_id: string,
    model_name: string,
    score: number,
    score_missing_reason: ScoreMissingReason | null,
  ): Promise<WorkspaceSite> {
    return this.client
      .post('/api/v2/site_score/site-assessment-component', {
        site_id,
        model_name,
        score,
        score_missing_reason,
      })
      .then((res) => res.data)
  }
  
  editSite(siteId: string, paylaod: EditSiteParams): Promise<WorkspaceSite> {
    return editSite(this, siteId, paylaod)
  }

  async getSite(site_id: string): Promise<WorkspaceSite> {
    return this.client
      .get(`/api/v2/site/${site_id}`, {
      })
      .then((res) => res.data)
  }

  async updateSiteNote(siteId: string, notes: string) {
    return editSite(this, siteId, { notes })
  }

  async regionConfiguration(): Promise<RegionConfiguration> {
    return this.client.get('/api/v2/configuration').then(({ data }) => data)
  }

  applyFeasibilityChecker(params: FeasibilityCheckerParams): Promise<{message: string}> {
    return this.client.post('/api/v2/feasibility_checker', params).then(res => res.data)
  }


  async getFeasibilityChecker(assessmentName: string, workspace_id: string): Promise<FeasibilityValue[]> {
    return await this.client
      .get<FeasibilityValue[]>(`/api/v2/feasibility_checker?assessment_name=${assessmentName}&workspace_id=${workspace_id}`)
      .then((res) => res.data)
  }

  async getDefaultFeasibilityChecker(assessmentName: string): Promise<Constraint[]> {
    return await this.client
      .get<Constraint[]>(`/api/v2/feasibility_checker/default?assessment_name=${assessmentName}`)
      .then((res) => (res.data))
  }

  async getDocxFile(site_ids: string[], file_name: string): Promise<Blob> {
    return this.client.post('/api/v2/reporting/docx', {site_ids, file_name}, {
      responseType: 'blob',
    }).then((res) => {
      return res.data
    })
  }

  async getSingleSiteDocxFile(site_id: string, file_name: string): Promise<Blob> {
    return this.client.post('/api/v2/reporting/single-site-docx', {site_id, file_name}, {
      responseType: 'blob',
    }).then((res) => {
      return res.data
    })
  }

  async getExcelFile(site_ids: string[], file_name: string): Promise<Blob> {
    return this.client.post('/api/v2/reporting/excel', { site_ids, file_name }, {
      responseType: 'blob',
    }).then((res) => {
      return res.data
    })
  }

  async getExportFile(site_ids: string[], file_name: string): Promise<Blob> {
    return this.client.post('/api/v2/reporting/export', { site_ids, file_name }, {
      responseType: 'blob',
    }).then((res) => {
      return res.data
    })
  }

  async getCsvFile(site_ids: string[], file_name: string): Promise<Blob> {
    return this.client.post('/api/v2/reporting/csv', { site_ids, file_name }, {
      responseType: 'blob',
    }).then((res) => {
      return res.data
    })
  }

  async getSingleSiteExcelFromTemplate(site_id: string, file_name: string): Promise<Blob> {
    return this.client.post('/api/v2/reporting/single-site-template-excel', { site_id, file_name }, {
      responseType: 'blob',
    }).then((res) => {
      return res.data
    })
  }

  async getToastIgnore(): Promise<ToastIgnore[]> {
    return this.client
      .get<ToastIgnore[]>('/api/v2/organization/toast_ignore')
      .then(({ data }) => data)
  }

  async setToastIgnore(ignore: ToastIgnore[]): Promise<void> {
    return this.client
      .post('/api/v2/organization/toast_ignore', ignore)
      .then(({ data }) => data)
  }

  siteBulkUpdate(
    site_ids: string[],
    status_id: string | null,
    notes: string | null,
    chargers: SiteCharger[],
    checklist_ids: string[],
    notes_remove_all: boolean,
    chargers_remove_all: boolean,
    checklists_remove_all: boolean, 
  ): Promise<void> {
    return this.client
      .put('/api/v2/site/bulk', {
        site_ids,
        status_id,
        notes,
        chargers,
        checklist_ids,
        notes_remove_all,
        chargers_remove_all,
        checklists_remove_all,
      })
  }

  // Bulk edit section
  siteBulkDelete(site_ids: string[], deleted_reason = 'Bulk Delete'): Promise<void> {
    return this.client
      .delete('/api/v2/site/bulk', {
        data: {
          site_ids,
          deleted_reason,
        },
      })
  }

  async getSiteParameters(site_id: string, assessment_name: string): Promise<SiteParameter[]> {
    const data = { site_ids: [site_id], assessment_name }
    return this.client
      .post('/api/v2/site-parameters/values', data)
      .then((res) => res.data[site_id])
  }

  async getSiteParametersMultipleSites(site_ids: string[], assessment_name: string | null): Promise<SiteParameter[]> {
    const data = { site_ids: site_ids, assessment_name }
    return this.client
      .post('/api/v2/site-parameters/values', data)
      .then((res) => res.data)
  }

  async saveSiteParameters(site_id: string, site_params: PartialSiteParameter[]): Promise<void> {
    const data = { site_id: site_id, new_values: site_params }
    return this.client
      .post('/api/v2/site-parameters/update', data)
      .then((res) => res.data)
  }

  async recalculateScoreUserInput(site_id: string): Promise<void> {
    return this.client
      .get(`/api/v2/site_score/recalculate-scores-user-input/${site_id}`)
      .then((res) => res.data)
  }

  async getWorkspaces() {
    return this.client
      .get<WorkspaceIndexItem[]>('/api/v2/workspace')
      .then((res) => res.data as WorkspaceIndexItem[])
  }

  async getWorkspaceHistory(): Promise<WorkspaceHistory[]> {
    return this.client
      .get('/api/v2/workspace/history')
      .then(res => res.data)
  }

  async deleteWorkspace(
    id: string,
    reason = 'Deleted manually',
  ): Promise<void> {
    return this.client
      .delete(`/api/v2/workspace/${id}`, {
        data: {
          deleted_reason: reason,
        },
      })
      .then((res) => res.data)
  }

  async deleteWorkspaceHistoryItem(id: string): Promise<WorkspaceHistory[]> {
    return this.client.delete(`/api/v2/workspace/history/${id}`)
      .then(res => res.data)
  }

  async getWorkspaceChargerSetups(workspaceId: string): Promise<WorkspaceChargerSetups> {
    return this.client
      .get(`/api/v2/charger/${workspaceId}/workspace-chargers-by-site`)
      .then(({ data }) => data)
  }

  async getWorkspaceChecklists(workspaceId: string): Promise<WorkspaceChecklists> {
    return this.client
      .get(`/api/v2/checklist/${workspaceId}/workspace-checklists-by-site`)
      .then(({ data }) => data)
  }

  generateParkingSites(payload: ParkingSitePayload): Promise<{ n_sites_generated: number }> {
    return this.client
      .post('/api/v2/predictions/generate-parking-sites', payload)
      .then(({ data }) => data)
  }

  async getPublicSiteCaptures(sitePublicId: string): Promise<SitePublicCaptures> {
    return this.client
      .get(`/api/v2/site-capturing/public/${sitePublicId}`, {}).then(res => {
        return res.data
      })
  }

  async getDatasetSources(): Promise<DataSource[]> {
    return this.client.get('/api/v1/dataset/license', {
      baseURL: this.datasetBaseUrl,
    })
      .then(data => data.data)
  }

  landAnalysisFilteringByCompany(companyName: string): Promise<LandAnalysisCompany[]> {
    return this.client
      .get(`/api/v1/land-registry/companies/${companyName}`, {
        baseURL: this.datasetBaseUrl,
      }).then(res => {
        return res.data
      })
  }
  
  getCompanyData(companyName: string, region_id?: number): Promise<CompanyTitle[]> {
    const payload: Record<string, number> = {}

    if (region_id) {
      payload['region_id'] = region_id
    }
    const query = new URLSearchParams(payload as any)

    return this.client
      .get(`/api/v1/land-registry/companies/${companyName}/titles?${query.toString()}`, {
        baseURL: this.datasetBaseUrl,
      }).then(res => {
        return res.data
      })
  }

  getLandAnalysisGeometry(companyTitle: string, title: string): Promise<Feature<Polygon, LandTitle>> {
    return this.client
      .get(`/api/v1/land-registry/companies/${companyTitle}/titles/${title}`, {
        baseURL: this.datasetBaseUrl,
      }).then(res => {
        return res.data
      })
  }

  getLabelSets(): Promise<LabelSet[]> {
    return this.client.get('/api/v2/label_set').then(res => res.data)
  }

  updateLabelSets(req: UpdateLabelSetsRequest): Promise<LabelSet[]> {
    return this.client.post('/api/v2/label_set/update', req).then(res => res.data)
  }

  deleteLabelSet(id: string): Promise<void> {
    return this.client.delete(`/api/v2/label_set/${id}`).then(res => res.data)
  }

  addSiteLabel(siteId: string, labelId: string): Promise<void> {
    return this.client
      .post(`/api/v2/label_set/${siteId}/add_label/${labelId}`)
      .then(res => res.data)
  }

  removeSiteLabel(siteId: string, labelId: string): Promise<void> {
    return this.client
      .delete(`/api/v2/label_set/${siteId}/remove_label/${labelId}`)
      .then(res => res.data)
  }

  removeAllSiteLabels(siteId: string): Promise<void> {
    return this.client
      .delete(`/api/v2/label_set/${siteId}/remove_all_labels`)
      .then(res => res.data)
  }
  
  getGenericDatasetPartialStart(datasetPayload: DatasetPayload): Promise<BatchResponse> {

    return this.client
      .post('/api/v1/generic_dataset/dataset/partial/start', datasetPayload, {
        baseURL: this.datasetBaseUrl,
      }).then(res => {
        return res.data
      })
  }

  getGenericDatasetStorePart(payload: StorePayload): Promise<any> {
    const formData = new FormData()
    formData.append('file', payload.file)

    let extraProps = ''

    if (payload.additional_properties) {
      extraProps = `&additional_properties=${payload.additional_properties}`
    }

    return this.client
      .post(`/api/v1/generic_dataset/dataset/partial/store_part?file_part=${payload.file_part}&batch_id=${payload.batch_id}&name_property=name&value_property=value${extraProps}`, formData, {
        baseURL: this.datasetBaseUrl,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(res => {
        return res.data
      })
  }

  getGenericDatasetEnd(batchId: number): Promise<any> {
    return this.client
      .post(`/api/v1/generic_dataset/dataset/partial/end?batch_id=${batchId}`, null, {
        baseURL: this.datasetBaseUrl,
      }).then(res => {
        return res.data
      })
  }
}

interface StorePayload {
  file_part: number
  batch_id: number
  name_property?: string
  value_property?: string
  additional_properties?: string
  file: File
}

interface BatchResponse {
  dataset_id: number,
  source_id: number,
  batch_id: number,
  processed_parts: null | number
}