import {IconDefinition, icons} from '@/types/icons'
import { ChargerFeatureProperties,
  ChargerIcon,
  ChargerMarkerType,
  ChargerOwnership,
  ChargerSpeed,
} from '@/types/app'

import sharedComponentsUtils from '@/utils/sharedComponents.utils'
import {VectorUtils} from '@/utils/vector.utils'
import GlobalUtils from '@/utils/global.utils'
import {pretty} from '@/libs/texts'
import { ChargerUtils } from '@/utils/ChargerUtils'
import { RegionUtils } from '@/utils/region.utils'

const svgNamespace = 'http://www.w3.org/2000/svg'

export interface Config {
  id: string
  fallbackId?: string
  fill?: string
  stroke?: string
  label?: string

  // stroke is by default set to be white
  badge?: {
    fill: string,
  }
}

/**
 * Gets the webpack url of the specified asset
 */
export function getAssetUrl(path: string): string {
  return GlobalUtils.getLocalImage(path)
}

export function getAssetFromPublicUrl(path: string): string {
  return new URL(path, import.meta.url).href
}

export function chargerIcon(type: ChargerMarkerType, speed: ChargerSpeed = 'slow'): string {
  function siteTypeMapper(): string {
    switch (type.toLowerCase()) {
      case 'in review':
      case 'verified':
        return 'review'
      case 'own':
        return 'own'
      case 'proposed':
        return 'proposed'
      case 'ai generated':
        return 'ai generated'
      case 'rejected':
        return 'rejected'
      case 'approved':
        return 'approved'
      case 'competition':
        return 'competition'
      default:
        return 'review'
    }
  }

  const url = type === 'ai generated' ? 'charger-proposed-generated.svg' : `charger-${siteTypeMapper()}-${speed}.svg`

  return getAssetUrl('/assets/map/markers/' + url)
}

export function inline(config: Config): string {
  return uriEncode(assemble(config).outerHTML)
}

export function svg(config: Config): string {
  return assemble(config).outerHTML
}

function uriEncode(str: string): string {
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(str)
}

function assemble(cfg: Config): SVGElement {
  let ico: IconDefinition
  let size = [26, 26]
  let center = 0

  const { id = cfg.fallbackId, fallbackId = 'default', fill = '#FFFFFF', stroke } = cfg

  if (id !== undefined && icons[id] !== undefined) {
    ico = icons[id]
  } else {
    ico = icons[fallbackId]
  }

  const layers: SVGElement[] = []
  if (cfg.badge !== undefined) {
    layers.push(svgNode('circle', {
      fill: cfg.badge.fill,
      stroke: '#FFFFFF',
      'stroke-width': '3',
      cx: '22',
      cy: '22',
      r: '16',
    }))
    size = [44, 44]
    center = 10
  }

  const [width, height] = ico.size || size

  const root = svgNode(
    'svg',
    {
      width: `${width}px`,
      height: `${height}px`,
      viewBox: `0 0 ${width} ${height}`,
    },
    ...layers,
  )

  root.setAttribute('xmlns', svgNamespace)

  const [x, y] = ico.offset || [0, 0]
  const transform = `translate(${center + x}, ${center + y})`
  const grp = svgNode('g', { transform })

  if (ico.rawSvg) {
    ico.rawSvg.forEach((svg) => {
      const args = { ...svg.args, stroke: fill, fill }
      grp.append(svgNode(svg.tag, args))
    })
  }

  ico.path.forEach((d: string) => {
    grp.append(svgNode('path', { d, fill, stroke }))
  })

  root.append(grp)

  return root
}

/**
 * Combines any amount of SVG images into one, by appending them in order.
 *
 * Returns a URI encoded string of the SVG content
 **/
export function combineSvg(...svgs: any[]): string {
  // We are creating two new groups which are in the end are inserted into the svg
  // where the first group contains the charger icon and the second group contains
  // the number of chargers

  const chargerIconGroup = document.createElement('g')

  chargerIconGroup.innerHTML = svgs[0]
  
  const counterIconGroup = document.createElement('g')

  counterIconGroup.innerHTML = svgs[1]

  const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 26px 26px">${[
    // the replaceAll is used for fixing the issue of the svg gradient element and property name being
    // set to lower caser rather than cameCase
    chargerIconGroup.outerHTML
      .replaceAll('lineargradient', 'linearGradient')
      .replaceAll('gradientunits', 'gradientUnits'),
    counterIconGroup.outerHTML,
  ].join('')}</svg>`

  return uriEncode(newSvg)
}

export function combineChargerClusterSvg(baseSvg: string, iconSvg: string): string {
  const chargerClusterGroup = document.createElement('g')
  chargerClusterGroup.innerHTML = baseSvg

  const counterIconGroup = document.createElement('g')
  counterIconGroup.innerHTML = iconSvg
  counterIconGroup.setAttribute('transform', 'translate(42, 3) scale(1.25)')

  const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t width="64" height="64" viewBox="0 0 64 64" xml:space="preserve"><style type="text/css">
\t.st0{fill:#FFFFFF;}
</style>${[
    chargerClusterGroup.outerHTML,
    counterIconGroup.outerHTML,
  ].join('')}</svg>`

  return uriEncode(newSvg)
}

/**
 * Returns a URI encoded string of a charger icon SVG element
 * based on its type and speed, with an EVSE count on top of it
 */
export function createChargerIcon(
  chargerType: ChargerOwnership,
  speeds: ChargerSpeed[],
  evseCount: number,
  filter: ChargerSpeed[] = [],
): string {

  const filteredCpTypes = speeds
    .filter((key) => filter.length === 0 || filter.includes(key))


  const highestChargerSpeed = filteredCpTypes[0] ?? 'slow'
  const { chargerIcon, evseCountIcon } = selectChargerIcons(chargerType, highestChargerSpeed, evseCount)
  return combineSvg(chargerIcon, evseCountIcon)
}

export function createAllChargerIcons(
  chargerType: ChargerOwnership,
  properties: ChargerFeatureProperties,
): ChargerIcon[] {
  const activeTypes = ChargerUtils.getActiveTypes(properties.categorization)

  const evseInfo = properties.evse_info

  return activeTypes.map((chargerSpeed) => {
    const evseCount = evseInfo.filter(info => {
      if (RegionUtils.isUk()) {
        return info.max_power_categorization === chargerSpeed
      } else {
        return info.power_type_categorization === chargerSpeed
      }
    }).length

    const {chargerIcon, evseCountIcon} = selectChargerIcons(chargerType, chargerSpeed, evseCount)
    return {icon: combineSvg(chargerIcon, evseCountIcon), speed: pretty(chargerSpeed)}
  })
}

export function createChargerGraphIcon(
  chargerType: ChargerOwnership,
  chargerSpeed: ChargerSpeed,
  evseCount: number,
  graphValues: [number, number],
  graphColors: [string, string],
): string {

  const { chargerIcon, evseCountIcon } = selectChargerIcons(chargerType, chargerSpeed, evseCount)

  return combineSvgEvcs(graphValues, graphColors, chargerIcon, evseCountIcon)

}

const selectChargerIcons = (
  chargerType: ChargerOwnership,
  chargerSpeed: ChargerSpeed,
  evseCount: number,
) => {
  let chargerIcon: string = ''
  let evseCountIcon: string | undefined

  // I wish there was a cleaner/dynamic way to do this, but unfortunately it can't be done differently
  // due to loader imports being resolved at compile time.
  if (chargerType === 'own') {
    switch (chargerSpeed) {
      case 'slow':
        chargerIcon = sharedComponentsUtils.ChargerOwnSlow
        break
      case 'fast':
        chargerIcon = sharedComponentsUtils.ChargerOwnFast
        break
      case 'rapid':
        chargerIcon = sharedComponentsUtils.ChargerOwnRapid
        break
      case 'ultra_rapid':
        chargerIcon = sharedComponentsUtils.ChargerOwnUltraRapid
        break
    }
  } else if (chargerType === 'econnect') {
    switch (chargerSpeed) {
      case 'slow':
        chargerIcon = sharedComponentsUtils.ChargerEconnectSlow
        break
      case 'fast':
        chargerIcon = sharedComponentsUtils.ChargerEconnectFast
        break
      case 'rapid':
        chargerIcon = sharedComponentsUtils.ChargerEconnectRapid
        break
      case 'ultra_rapid':
        chargerIcon = sharedComponentsUtils.ChargerEconnectUltraRapid
        break
    }
  } else {
    switch (chargerSpeed) {
      case 'slow':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionSlow
        break
      case 'fast':
      case 'level_2':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionFast
        break
      case 'rapid':
      case 'level_3_dc_fast':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionRapid
        break
      case 'ultra_rapid':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionUltraRapid
        break
    }
  }

  const evseCountIcons = [
    undefined, // No icon if count is 0
    sharedComponentsUtils.MarkerNumber1,
    sharedComponentsUtils.MarkerNumber2,
    sharedComponentsUtils.MarkerNumber3,
    sharedComponentsUtils.MarkerNumber4,
    sharedComponentsUtils.MarkerNumber5,
    sharedComponentsUtils.MarkerNumber6,
    sharedComponentsUtils.MarkerNumber7,
    sharedComponentsUtils.MarkerNumber8,
  ]

  if (!evseCount || evseCount < 0) {
    evseCountIcon = ''
  } else if (evseCount < 9) {
    evseCountIcon = evseCountIcons[evseCount]
  } else {
    evseCountIcon = sharedComponentsUtils.MarkerNumber8Plus
  }

  return {
    evseCountIcon,
    chargerIcon,
  }
}

export function selectCLusterCountIcon(count: number): string {
  let evseCountClusterIcon: string = ''
  const evseClusterCountIcons = [
    undefined,
    sharedComponentsUtils.ClusterNumber01,
    sharedComponentsUtils.ClusterNumber02,
    sharedComponentsUtils.ClusterNumber03,
    sharedComponentsUtils.ClusterNumber04,
    sharedComponentsUtils.ClusterNumber05,
    sharedComponentsUtils.ClusterNumber06,
    sharedComponentsUtils.ClusterNumber07,
    sharedComponentsUtils.ClusterNumber08,
    sharedComponentsUtils.ClusterNumber09,
    sharedComponentsUtils.ClusterNumber10,
    sharedComponentsUtils.ClusterNumber11,
    sharedComponentsUtils.ClusterNumber12,
    sharedComponentsUtils.ClusterNumber13,
    sharedComponentsUtils.ClusterNumber14,
    sharedComponentsUtils.ClusterNumber15,
    sharedComponentsUtils.ClusterNumber16,
    sharedComponentsUtils.ClusterNumber17,
    sharedComponentsUtils.ClusterNumber18,
    sharedComponentsUtils.ClusterNumber19,
    sharedComponentsUtils.ClusterNumber20,
    sharedComponentsUtils.ClusterNumber21,
    sharedComponentsUtils.ClusterNumber22,
    sharedComponentsUtils.ClusterNumber23,
    sharedComponentsUtils.ClusterNumber24,
    sharedComponentsUtils.ClusterNumber25,
    sharedComponentsUtils.ClusterNumber26,
    sharedComponentsUtils.ClusterNumber27,
    sharedComponentsUtils.ClusterNumber28,
    sharedComponentsUtils.ClusterNumber29,
    sharedComponentsUtils.ClusterNumber30,
    sharedComponentsUtils.ClusterNumber31,
    sharedComponentsUtils.ClusterNumber32,
    sharedComponentsUtils.ClusterNumber33,
    sharedComponentsUtils.ClusterNumber34,
    sharedComponentsUtils.ClusterNumber35,
    sharedComponentsUtils.ClusterNumber36,
    sharedComponentsUtils.ClusterNumber37,
    sharedComponentsUtils.ClusterNumber38,
    sharedComponentsUtils.ClusterNumber39,
    sharedComponentsUtils.ClusterNumber40,
    sharedComponentsUtils.ClusterNumber41,
    sharedComponentsUtils.ClusterNumber42,
    sharedComponentsUtils.ClusterNumber43,
    sharedComponentsUtils.ClusterNumber44,
    sharedComponentsUtils.ClusterNumber45,
    sharedComponentsUtils.ClusterNumber46,
    sharedComponentsUtils.ClusterNumber47,
    sharedComponentsUtils.ClusterNumber48,
    sharedComponentsUtils.ClusterNumber49,
    sharedComponentsUtils.ClusterNumber50,
    sharedComponentsUtils.ClusterNumber51,
    sharedComponentsUtils.ClusterNumber52,
    sharedComponentsUtils.ClusterNumber53,
    sharedComponentsUtils.ClusterNumber54,
    sharedComponentsUtils.ClusterNumber55,
    sharedComponentsUtils.ClusterNumber56,
    sharedComponentsUtils.ClusterNumber57,
    sharedComponentsUtils.ClusterNumber58,
    sharedComponentsUtils.ClusterNumber59,
    sharedComponentsUtils.ClusterNumber60,
    sharedComponentsUtils.ClusterNumber61,
    sharedComponentsUtils.ClusterNumber62,
    sharedComponentsUtils.ClusterNumber63,
    sharedComponentsUtils.ClusterNumber64,
    sharedComponentsUtils.ClusterNumber65,
    sharedComponentsUtils.ClusterNumber66,
    sharedComponentsUtils.ClusterNumber67,
    sharedComponentsUtils.ClusterNumber68,
    sharedComponentsUtils.ClusterNumber69,
    sharedComponentsUtils.ClusterNumber70,
    sharedComponentsUtils.ClusterNumber71,
    sharedComponentsUtils.ClusterNumber72,
    sharedComponentsUtils.ClusterNumber73,
    sharedComponentsUtils.ClusterNumber74,
    sharedComponentsUtils.ClusterNumber75,
    sharedComponentsUtils.ClusterNumber76,
    sharedComponentsUtils.ClusterNumber77,
    sharedComponentsUtils.ClusterNumber78,
    sharedComponentsUtils.ClusterNumber79,
    sharedComponentsUtils.ClusterNumber80,
    sharedComponentsUtils.ClusterNumber81,
    sharedComponentsUtils.ClusterNumber82,
    sharedComponentsUtils.ClusterNumber83,
    sharedComponentsUtils.ClusterNumber84,
    sharedComponentsUtils.ClusterNumber85,
    sharedComponentsUtils.ClusterNumber86,
    sharedComponentsUtils.ClusterNumber87,
    sharedComponentsUtils.ClusterNumber88,
    sharedComponentsUtils.ClusterNumber89,
    sharedComponentsUtils.ClusterNumber90,
    sharedComponentsUtils.ClusterNumber91,
    sharedComponentsUtils.ClusterNumber92,
    sharedComponentsUtils.ClusterNumber93,
    sharedComponentsUtils.ClusterNumber94,
    sharedComponentsUtils.ClusterNumber95,
    sharedComponentsUtils.ClusterNumber96,
    sharedComponentsUtils.ClusterNumber97,
    sharedComponentsUtils.ClusterNumber98,
    sharedComponentsUtils.ClusterNumber99,
    sharedComponentsUtils.ClusterNumber100,
  ]

  if (count < 0) {
    evseCountClusterIcon = ''
  } else if (count < 100) {
    evseCountClusterIcon = evseClusterCountIcons[count] || ''
  } else {
    evseCountClusterIcon = sharedComponentsUtils.ClusterNumber100
  }

  return evseCountClusterIcon
}

function svgNode(tag: string, args: Record<string, string | undefined> = {}, ...children: SVGElement[]): SVGElement {
  const el = document.createElementNS(svgNamespace, tag)

  for (const arg in args) {
    if (args[arg] !== undefined) {
      el.setAttributeNS(null, arg, args[arg] as string)
    }
  }

  el.append(...children)

  return el
}

/**
 * The following converts (currently known) street furniture types to the icons known by the system
 */
export function convertStreetFurnitureTypeToIcon(type: string): string {
  switch (true) {
    case /metered/i.test(type):
      return 'feederPillar'

    case /sign/i.test(type):
      return 'trafficSign'

    case /lamp/i.test(type):
      return 'streetLamp'

    case /ticket/i.test(type):
      return 'ticketMachine'

    case /telephone/i.test(type):
      return 'telephoneBox'

    case /junction/i.test(type):
      return 'junction'

    case /crossing/i.test(type):
      return 'levelCrossing'

    case /bus/i.test(type):
      return 'busStop'

    default:
      return 'default'
  }
}

export function catalogueItemIcon(id: string, fill = '#B0B7BF', badge?: Config['badge']): string {
  return inline({ id, fill, badge })
}
// Custom version for own evcs valuse


export function combineSvgEvcs(utilizationScore: [number, number], colors: string[], ...svgs: any[]): string {
  // We are creating two new groups which are in the end are inserted into the svg
  // where the first group contains the charger icon and the second group contains
  // the number of chargers

  const chargerIconGroup = document.createElement('g')
  chargerIconGroup.setAttribute('transform', 'scale(0.9) translate(8 8)')
  chargerIconGroup.innerHTML = svgs[0]

  const counterIconGroup = document.createElement('g')

  counterIconGroup.innerHTML = svgs[1]

  const g = counterIconGroup.firstChild as HTMLElement
  g.setAttribute('transform', 'translate(30 0)')

  const donutGroup = document.createElement('g')

  donutGroup.innerHTML = VectorUtils.generateDonutSvg(utilizationScore, colors)


  const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="44px" height="44px" viewBox="0 0 44px 44px">${[
    donutGroup.outerHTML,
    chargerIconGroup.outerHTML,
    counterIconGroup.outerHTML,
  ].join('')}</svg>`

  return uriEncode(newSvg)
}

export function coloredSiteMarker(color: string): string {
  return uriEncode(`
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 1c6.334 0 12 4.684 12 11.918 0 2.238-.89 4.77-2.813 7.62-1.92 2.846-4.829 5.949-8.76 9.309a.693.693 0 0 1-.87 0c-3.923-3.36-6.828-6.463-8.746-9.31C4.891 17.689 4 15.158 4 12.919 4 5.684 9.666 1 16 1Z" fill="${color}" stroke="#fff" stroke-width="2"/>
    </svg>
  `)
}
