import {DatasetData, LayerPolygonData, SimpleOption} from '@/utils/layers/layout-config'
import {
  ALTERNATIVE_FUEL_CORRIDORS,
  PRETTY_BLUE_STROKE_DEFAULT_STYLE,
  PRETTY_BLUE_STYLE,
  PRETTY_BLUE_WHITE_STYLE,
  TAX_CREDIT_STYLE,
  TRAFFIC_STYLE,
} from '@/utils/layers/layerStyles.utils'
import { LayerRole } from '@/auth/roles'
import MapStyleUtils from '../mapStyle.utils'

export const taxCreditDataLayer = 'US 30c tax credit data'
export const usDnoCoverageData = 'US DNO coverage data'
export const alternativeFuelCorridors = 'Alternative Fuel Corridors'
export const justice40tracts = 'Justice40 tracts'

interface PartialLayer {
  name: string
  zoom: number
  role?: LayerRole
}

const layers: Record<string, PartialLayer[]> = {
  'Traffic': [
    { name: 'NY State Department Of Transportation: Vehicle Miles Travelled', zoom: 12, role: LayerRole.trafficFromNYStateDepartment },
    { name: 'NY State Department Of Transportation: Average Daily Traffic Count', zoom: 12, role: LayerRole.trafficFromNYStateDepartment },
    { name: 'NY State Department Of Transportation: Vehicle Miles Travelled, buffered 1-lane', zoom: 12, role: LayerRole.trafficFromNYStateDepartment },
    { name: 'NY State Department Of Transportation: Average Daily Traffic Count, buffered 1-lane', zoom: 12, role: LayerRole.trafficFromNYStateDepartment },
    { name: 'US traffic data', zoom: 12, role: LayerRole.trafficDensity },
  ],
  'Demographic': [
    { name: 'Housing units density per sq. km, US Census', zoom: 13 },
    { name: 'Household income, US Census', zoom: 13 },
    { name: 'Number of vehicles per household, US Census', zoom: 13 },
    { name: 'Percentage of using car, van or truck as transportation to work, US Census', zoom: 13 },
    { name: 'Multi unit housing percentage, US Census', zoom: 13 },
    { name: 'Number of vehicles available, US Census', zoom: 13 },
    { name: 'Median travel time to work, US Census', zoom: 13 },
    { name: 'Work outside of area of residency, percentage, US Census', zoom: 13 },
    { name: 'Median time of departure to work, for workers departing [5:00 - 10:00], US Census', zoom: 13 },
    { name: 'Percentage below poverty level, US Census', zoom: 13 },
    { name: 'Education: Percentage with bachelor\'s degree or above, US Census', zoom: 13 },
    { name: 'Population density per square km, US Census', zoom: 13 },
    { name: 'Housing: percentage of occupied housing units, US Census', zoom: 13 },
  ],
  'Other': [
    { name: 'EV registration counts', zoom: 9 },
    { name: alternativeFuelCorridors + ' 1-mile area, rounds 1-6', zoom: 9 },
    { name: alternativeFuelCorridors + ' 1-mile catchment area, rounds 1-6', zoom: 9 },
    { name: taxCreditDataLayer, zoom: 11 },
    { name: usDnoCoverageData, zoom: 9 },
    { name: justice40tracts, zoom: 9 },
  ],
}


export const layersUS: DatasetData[] = Object.entries(layers).flatMap(([group, items]) => {
  // Change styles
  if (group === 'Traffic') {
    return items.map(({ name, zoom, role }) => {
      return new LayerPolygonData({
        name,
        group,
        role,
        minZoom: zoom,
        dataset: name,
        options: new SimpleOption('value'),
        paintStyle: TRAFFIC_STYLE,
        mapTypeStyle: MapStyleUtils.greyscaleMapStyles,
        disableMinMax: false,
        genericApi: true,
        tooltips: [
          { text: 'Average Annual Daily Traffic:', property: 'value' },
        ],
      })
    })
  }
  return items.map(({ name, zoom, role }) => {
    if (name === taxCreditDataLayer) {
      const valueStyle: Partial<CSSStyleDeclaration> = {
        color: 'var(--Gray-100, #212529)',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
      }
      const labelStyle: Partial<CSSStyleDeclaration> = {
        display: 'none',
      }

      return new LayerPolygonData({
        name,
        group,
        role,
        dataset: name,
        options: new SimpleOption('tract'),
        paintStyle: TAX_CREDIT_STYLE,
        disableMinMax: true,
        genericApi: true,
        minZoom: 11,
        tooltips: [
          {
            text: 'Tract ID:',
            property: 'tract',
            valueStyle,
            labelStyle,
            valueTransform: (value: any) => {
              switch (value) {
                case 1:
                  return 'Eligible tract through 2024 (2011-2015 NMTC tracts)'
                case 2:
                case 3:
                  return 'Eligible tract through 2029 (2016-2020 NMTC tracts)'
                case 4:
                case 5:
                case 6:
                case 7:
                  return 'Eligible tract through 2030 (2020 Non-Urban tracts)'
                default:
                  return value
              }
            },
          },
        ],
      })
    }

    if (name === usDnoCoverageData) {
      return new LayerPolygonData({
        name: 'US Utility coverage data',
        group,
        role,
        minZoom: zoom,
        dataset: name,
        options: new SimpleOption('value'),
        paintStyle: PRETTY_BLUE_STROKE_DEFAULT_STYLE,
        paintStyleOptions: {
          'roadmap': PRETTY_BLUE_STROKE_DEFAULT_STYLE,
          'hybrid': PRETTY_BLUE_STROKE_DEFAULT_STYLE,
        },
        disableMinMax: false,
        genericApi: true,
        tooltips: [
          { text: 'Utility Name:', property: 'name' },
        ],
      })
    }

    if (name.includes(alternativeFuelCorridors)) {
      return new LayerPolygonData({
        name,
        group,
        role,
        minZoom: zoom,
        dataset: name,
        options: new SimpleOption('value'),
        paintStyle: ALTERNATIVE_FUEL_CORRIDORS,
        paintStyleOptions: {
          'roadmap': ALTERNATIVE_FUEL_CORRIDORS,
          'hybrid': ALTERNATIVE_FUEL_CORRIDORS,
        },
        disableMinMax: false,
        genericApi: true,
        tooltips: [
          { text: 'Utility Name:', property: 'name' },
        ],
      })
    }

    if (name === justice40tracts) {
      return new LayerPolygonData({
        minZoom: 9,
        name: 'Justice40 tracts',
        group: 'Other',
        description: 'Justice40 Disadvantaged Tracts by State',
        dataset: 'JUSTICE_40_TRACTS',
        options: new SimpleOption('disadvantaged'),
        paintStyle: PRETTY_BLUE_STYLE,
        paintStyleOptions: {
          'roadmap': PRETTY_BLUE_STYLE,
          'hybrid': PRETTY_BLUE_WHITE_STYLE,
        },

        disableMinMax: true,
        genericApi: false,
      
        tooltips: [
          {
            text: 'State',
            property: 'state',
            rounded: 0,
          },
          {
            text: 'County',
            property: 'county',
            rounded: 0,
          },
          {
            text: 'Categorized as disadvantaged',
            property: 'disadvantaged',
            rounded: 0,
          },
        ],
      })
    }

    return new LayerPolygonData({
      name,
      group,
      role,
      minZoom: zoom,
      dataset: name,
      options: new SimpleOption('value'),
      paintStyle: PRETTY_BLUE_STYLE,
      paintStyleOptions: {
        'roadmap': PRETTY_BLUE_STYLE,
        'hybrid': PRETTY_BLUE_WHITE_STYLE,
      },
      disableMinMax: false,
      genericApi: true,
      tooltips: [
        { text: 'Name:', property: 'name' },
        { text: 'Value:', property: 'value' },
      ],
    })
  })
})
