import { PrimeVueConfiguration } from 'primevue/config'

function buttonStyling(options: any) {
  const baseClass = 'tw-border-solid tw-border-2 tw-text-base tw-rounded tw-font-semibold tw-py-2 tw-px-4'

  if (options.attrs.outlined) {
    return `${baseClass} tw-border-indigo-600`
  }

  if (options.attrs.variant === 'light') {
    return `${baseClass}`
  } else if (options.attrs.variant === 'secondary') {
    return `${baseClass}`
  } else if (options.attrs.variant === 'primary') {
    return baseClass + ' tw-button-dodona tw-border-transparent tw-text-white'
  }

  return 'tw-bg-teal-500 hover:bg-teal-700 tw-cursor-pointer tw-text-white tw-p-3 tw-border-round tw-border-none tw-flex tw-gap-2'
}

// tw-appearance-none used for styling 
const defaultCheckboxStyle = 'tw-mr-2 tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-focus:ring-blue-500 tw-dark:focus:ring-blue-600 tw-dark:ring-offset-gray-800 tw-focus:ring-2 tw-dark:bg-gray-700 tw-dark:border-gray-600'

export default {
  zIndex: {
    overlay: 1000,
    tooltip: 1040,
  },
  // this removes only the classes used for it
  unstyled: false,
  pt: {
    card: () => ({}),
    button: (options: any) => ({
      root: buttonStyling(options),
      secondary: 'tw-red-teal-500 hover:tw-bg-red-700',
      label: 'tw-text-white tw-font-bold tw-text-xl', // OR { class: 'text-white font-bold text-xl' }
      icon: 'tw-text-white tw-text-2xl',
    }),
    checkbox: (options) => {
      return ({
        root: options?.props?.disabled ? 'tw-flex tw-cursor-not-allowed' : 'tw-flex',
        input: `${defaultCheckboxStyle} ${options?.props?.disabled && 'tw-cursor-not-allowed'}`,
      })
    },

    overlaypanel: () => {
      return {
        root: 'overlay-dropdown',
      }
    },
    // p-datatable-thead
    datatable: () => ({
      headerRow: {
        root: 'table-custom-header',
      },
      tbody: {
        root: 'table-custom-body',
      },
    }),
    tabview: () => {
      return {
        nav: 'w-full h-full tw-flex tw-list-none tw-p-0 tw-full tab-custom tw-relative tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-flex-1 tw-border-b-2 tw-border-solid tw-border-gray-100 tw-cursor-pointer',
        inkbar: 'tw-border-b-2 tw-border-solid border-blue tw-absolute -tw-bottom-0.5	',
        tab: 'w-full h-full tw-bg-neutral-200 tw-cursor-pointer',
        tabpanel: 'w-full h-full tw-bg-neutral-200',
        
      }
    },
    directives: {
      tooltip: {
        root: ({ context}) => ({
          class: [
            // Position and Shadows
            'tw-absolute',
            'tw-p-fadein',
            'tw-max-w-[200px]',
            // Spacing
            {
              'tw-py-0 tw-px-1': (context === null ? undefined : context.right) || (context === null ? undefined : context.left) || !(context !== null && context.right) && !(context !== null && context.left) && !(context !== null && context.top) && !(context !== null && context.bottom),
              'tw-py-1 tw-px-0': (context === null ? undefined : context.top) || (context === null ? undefined : context.bottom),
            },
          ],
        }),
        arrow: ({ context }) => ({
          class: [
            // Position
            'tw-absolute',
            // Size
            'tw-w-0',
            'tw-h-0',
            // Shape
            'tw-border-transparent',
            'tw-border-solid',
            {
              'tw-border-y-[0.25rem] tw-border-r-[0.25rem] tw-border-l-0 tw-border-r-black': (context === null ? undefined : context.right) || !(context !== null && context.right) && !(context !== null && context.left) && !(context !== null && context.top) && !(context !== null && context.bottom),
              'tw-border-y-[0.25rem] tw-border-l-[0.25rem] tw-border-r-0 tw-border-l-black': context === null ? undefined : context.left,
              'tw-border-x-[0.25rem] tw-border-t-[0.25rem] tw-border-b-0 tw-border-t-black': context === null ? undefined : context.top,
              'tw-border-x-[0.25rem] tw-border-b-[0.25rem] tw-border-t-0 tw-border-b-black': context === null ? undefined : context.bottom,
            },
            // Spacing
            {
              '-tw-mt-1 ': (context === null ? undefined : context.right) || !(context !== null && context.right) && !(context !== null && context.left) && !(context !== null && context.top) && !(context !== null && context.bottom),
              '-tw-mt-1': context === null ? undefined : context.left,
              '-tw-ml-1': (context === null ? undefined : context.top) || (context === null ? undefined : context.bottom),
            },
          ],
        }),
        text: {
          class: [
            'tw-shadow-sm',
            'tw-shadow-black/25',
            'tw-p-3',
            'tw-bg-black',
            'dark:tw-bg-surface-700',
            'tw-text-white',
            'tw-leading-none',
            'tw-rounded-md',
            'tw-whitespace-pre-line',
            'tw-break-words',
          ],
        },
      },
    },
  },
} as PrimeVueConfiguration