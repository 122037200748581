import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson'
import { ChargerDetails, ChargerSpeed } from '@/types/app'
import { ScoreColorPresentation, ScoreMissingReason, SiteAssessmentScore } from '@/models/SiteSelection/SiteScore.model'
import {SiteCharger} from '@/models/admin/Configuration.model'

export type WorkspaceChange =
  | 'created'
  | 'updated'
  | 'scheduled-for-review'
  | 'deleted'
  | 'restored'

export interface Fleet {
  id: string
  name: string
  vehicles: FleetVehicle[]
  depot_location: google.maps.LatLngLiteral
}

export interface VehicleJourneyEstimates {
  percentage_of_journeys_0_50: number
  percentage_of_journeys_50_100: number
  percentage_of_journeys_100_125: number
  percentage_of_journeys_125_or_more: number
  first_journey: string
  last_journey: string
  average_daily_distance: number
  average_distance_last_7_days: number
  median_journey_length: number
  num_of_journeys: number
  number_of_journeys_above_150: number
  number_of_journeys_above_125: number
}

export interface FleetVehicle {
  id: string
  name: string
  type: string
  make: string
  model: string
  model_year?: number
  license_plate: string
  sleep_location: {
    coordinates: google.maps.LatLngLiteral
    sleep_percentage: number
    street: string
    street_number: number
  }
  max_daily_distance: number
  max_daily_distance_work: number
  site_id?: string
  cp_id?: string
}

export interface Workspace {
  id: string
  name: string
  status: string
  status_id: string

  region_id: number
  region_name: string
  boundingbox: google.maps.LatLngBoundsLiteral

  owner?: string
  owner_id?: string | null
  main_contact?: string

  created_at: Date
  created_by_email?: string
  locked: boolean

  geometry_geojson: Polygon | MultiPolygon

  number_of_sites: number

  sites: WorkspaceSite[]

  charge_points?: FeatureCollection<Point, FleetChargerProperties>

  // Private notes
  notes: string | null

  planning_level: PlanningLevel

  /**
   * IDs of models available for use in this workspace
   */
  available_assessments: string[]
  overriden_assessments: string[]

  fleet?: Fleet

  charger_details: ChargerDetails
}

export interface WorkspaceIndexItem {
  id: string
  name: string | null
  status_id: string 
  status: string

  region_id: number | null
  region_name: string
  boundingbox:
  | undefined
  | { south: number; west: number; north: number; east: number }

  owner: string | null
  owner_id: string | null
  main_contact: string | null

  number_of_sites: number
  locked: boolean
  created_at: string
}

export interface WorkspaceIndexItemSelected extends WorkspaceIndexItem {
  selected: boolean
}

export interface FleetChargerProperties {
  name: string
  evse_count: number
  cp_type: ChargerSpeed
}

export type WorkspaceSiteStatus =
  | 'proposed'
  | 'verified'
  | 'in review'
  | 'rejected'
  | 'approved'


export const workspaceSiteStatus: WorkspaceSiteStatus[] = [
  'proposed',
  'verified',
  'in review',
  'approved',
  'rejected',
]

export type WorkspaceSiteChange =
  | 'added'
  | 'generated'
  | 'updated'
  | 'approved'
  | 'unapproved'
  | 'verified'
  | 'unverified'
  | 'excluded'
  | 'included'
  | 'deleted'
  | 'restored'
  

export type StatusIcon = 'Proposed' | 'in review' | 'Rejected' | 'Verified' | 'generated' | 'Approved' | 'AI generated' | 'label_set'


export interface SiteScoreDetail {
  score: number,
  original_score: number,
  model_name: string,
  score_color: ScoreColorPresentation,
  score_name: SiteAssessmentScore,
  original_score_missing_reason: ScoreMissingReason
  score_missing_reason: ScoreMissingReason
}

export interface SiteProperties {
  created_at?: string | Date
  notes: string
  score: SiteScoreDetail[]
  aggregate_score: number
  aggregate_score_name: SiteAssessmentScore
  aggregate_score_color: ScoreColorPresentation
  public_id: string
  post: string
  street: string
  source: string
  status: string
  status_id: string
  assessment_name: string
  statusIcon: StatusIcon
  charge_point_id: number
  iteration_number: number
  chargers: SiteCharger[]
  description?: string
  projected_coordinate_system: [number, number]
  sockets: number
  socket_count: number
  workspace_id?: string
  workspace_name?: string
  created_by_email?: string
  charge_point_count: number
  passive_bays: number | null
  checklist_item_count: number
  created_by: string
  locked: boolean
  score_missing_reason?: ScoreMissingReason
  labels: string[]
}

export interface SiteStatusCount {
  status: string,
  count: number
}

export interface WorkspaceSite {
  id: string
  type: 'Feature'
  geometry: {
    coordinates: [number, number]
    type: 'Point'
  }
  properties: SiteProperties
}

export type HasSiteProperties = Pick<WorkspaceSite, 'properties'>

export type ExportType = 'json' | 'pdf' | 'xlsx' | 'csv' | 'docx' | 'export'

export interface SiteSource {
  id: string
  source: string
}

export type OriginalLabel = 'REJECTED' | 'APPROVED' | 'PROPOSED' | 'VERIFIED' | 'IN REVIEW'

export type OriginalWorkspaceLabel = 'Active' | 'Completed' | 'In review' | 'Canceled' | 'On hold'


export interface SiteStatus {
  id: string
  status: string
  archived: boolean
  is_default: boolean
  position: number
  protected: boolean
  // Types are initial values which don't change the string represents dynamic statuses name
  original_label: OriginalLabel | string
  
}

export interface WorkspaceNote {
  note: string
  user_email: string
  created_at: string
}

export enum PlanningLevel {
  country = 1,
  local = 2,
  fleet = 3,
}

export type ScoreSortType = 'Default' | 'Highest first' | 'Lowest first'

export interface NearbyEvseCount {
  slow: number
  fast: number
  rapid: number
  ultra_rapid: number
}
